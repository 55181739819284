import { gql } from "@apollo/client";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import {
  Kind,
  Role,
  useConfigurationCountQuery,
  useGetSourceWithTypeQuery,
  useSourcesQuery,
} from "../../graphql/generated";
import { useRole } from "../../hooks/useRole";
import { UpdateStatus } from "../../types/resources";
import { BPConfiguration, BPResourceConfiguration } from "../../utils/classes";
import { BPSource } from "../../utils/classes/source";
import { hasPermission } from "../../utils/has-permission";
import { trimVersion } from "../../utils/version-helpers";
import { ConfirmDeleteResourceDialog } from "../ConfirmDeleteResourceDialog";
import { EditResourceInUseWarningDialog } from "../Dialogs/EditResourceInUseWarningDialog/EditResourceInUseWarningDialog";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import { FormValues } from "../ResourceConfigForm";
import { DialogResource } from "../ResourceDialog";
import { EditResourceDialog } from "../ResourceDialog/EditResourceDialog";
import { ResourceCard } from "./ResourceCard";
import { onDeleteFunc } from "./types";

gql`
  query getSourceWithType($name: String!) {
    sourceWithType(name: $name) {
      source {
        metadata {
          name
          version
          id
          labels
          version
          displayName
        }
        spec {
          type
          parameters {
            name
            value
          }
          disabled
        }
      }
      sourceType {
        metadata {
          id
          name
          displayName
          version
          icon
          description
          stability
          additionalInfo {
            message
            documentation {
              text
              url
            }
          }
          resourceDocLink
        }
        spec {
          parameters {
            label
            name
            description
            required
            type
            default
            relevantIf {
              name
              operator
              value
            }
            documentation {
              text
              url
            }
            advancedConfig
            validValues
            options {
              multiline
              creatable
              trackUnchecked
              sectionHeader
              subHeader
              horizontalDivider
              gridColumns
              labels
              metricCategories {
                label
                column
                metrics {
                  name
                  description
                  kpi
                }
              }
              password
              processorContext
              sensitive
              variant
            }
          }
        }
      }
    }
  }
`;

interface ResourceSourceCardProps {
  name: string;
  sourceId: string;
  configuration: MinimumRequiredConfig;
  refetchConfiguration: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  onDeleteSuccess?: () => void;
}

export const ResourceSourceCard: React.FC<ResourceSourceCardProps> = ({
  name,
  sourceId,
  disabled,
  configuration,
  refetchConfiguration,
  readOnly,
  onDeleteSuccess,
}) => {
  const [editing, setEditing] = useState(false);
  const [confirmDeleteOpen, setDeleteOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const role = useRole();

  const trimmedSourceId = sourceId.split("_").slice(-1)[0]; // gets the id part of the long `source/<name>_<id>` even if <name> has an _

  const [
    editResourceInUseWarningDialogOpen,
    setEditResourceInUseWarningDialogOpen,
  ] = useState<{
    open: boolean;
    formValues: FormValues;
    function: "onSave" | "onToggle" | null;
  }>({
    open: false,
    formValues: {},
    function: null,
  });

  // Use the version name of the source specified in the configuration
  const versionedName = useMemo(() => {
    const sources = configuration?.spec?.sources;
    const source = sources?.find((s) => s.id === trimmedSourceId);

    return source?.name;
  }, [configuration?.spec?.sources, trimmedSourceId]);

  const { data, refetch: refetchSource } = useGetSourceWithTypeQuery({
    variables: { name: versionedName || name },
    fetchPolicy: "cache-and-network",
  });

  const [librarySources, setLibrarySources] = useState<DialogResource[]>();

  useSourcesQuery({
    onCompleted: (data) => {
      setLibrarySources(
        data.sources.map((s) => {
          return {
            metadata: {
              name: s.metadata.name,
            },
            spec: s.spec,
          };
        }),
      );
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("Failed to fetch Library sources", {
        variant: "error",
      });
    },
  });

  const { data: configurationCount } = useConfigurationCountQuery({
    variables: {
      query: `${Kind.Source}:${trimVersion(name)}`,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
      enqueueSnackbar(
        `Failed to get configuration count for ${Kind.Source} ${trimVersion(
          name,
        )}`,
        {
          variant: "error",
        },
      );
    },
  });

  function interceptOnSave(formValues: FormValues) {
    if (data?.sourceWithType?.source == null) {
      enqueueSnackbar("Cannot save source.", { variant: "error" });
      console.error("no source found when requesting source and type");
      return;
    }
    if (configurationCount?.configurationCount == null) {
      enqueueSnackbar("Cannot get configuration count.", { variant: "error" });
      console.error("Cannot get configuration count");
      return;
    }
    if (configurationCount.configurationCount > 1) {
      setEditResourceInUseWarningDialogOpen({
        open: true,
        formValues: formValues,
        function: "onSave",
      });
    } else {
      onSave(formValues);
    }
  }

  async function onSave(formValues: FormValues) {
    setEditResourceInUseWarningDialogOpen({
      open: false,
      formValues: {},
      function: null,
    });
    if (data?.sourceWithType?.source == null) {
      enqueueSnackbar("Cannot save source.", { variant: "error" });
      console.error("no source found when requesting source and type");
      return;
    }

    let updatedSource = new BPSource({
      metadata: {
        ...data.sourceWithType.source.metadata,
        displayName: formValues.displayName,
      },
      spec: data.sourceWithType.source.spec,
    });
    updatedSource.setParamsFromMap(formValues);

    try {
      const update = await updatedSource.apply();
      if (update.status === UpdateStatus.INVALID) {
        console.error("Invalid Update: ", update);
        throw new Error(`failed to apply source, got status ${update.status}`);
      }

      enqueueSnackbar("Saved Source!", { variant: "success" });

      refetchConfiguration();
      refetchSource();
      setEditing(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to update source.", { variant: "error" });
    }
  }

  const onDelete: onDeleteFunc | undefined = useMemo(() => {
    if (sourceId == null) {
      return undefined;
    }

    return async function onDelete() {
      const updatedConfig = new BPConfiguration(configuration);

      try {
        updatedConfig.removeSource(trimmedSourceId);
        const update = await updatedConfig.apply();
        if (update.status === UpdateStatus.INVALID) {
          console.error("Invalid Update: ", update);
          throw new Error(
            `failed to apply configuration, got status ${update.status}`,
          );
        }

        setEditing(false);
        setDeleteOpen(false);
        refetchConfiguration();
        refetchSource();
        onDeleteSuccess && onDeleteSuccess();
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Failed to remove source.", { variant: "error" });
      }
    };
  }, [
    configuration,
    enqueueSnackbar,
    onDeleteSuccess,
    refetchConfiguration,
    refetchSource,
    sourceId,
    trimmedSourceId,
  ]);

  /**
   * Unlink the source from the library. This will create a new source with the same parameters, but with a different ID
   * @param values Parameter values
   * @param name Name of the source in the Library
   */
  async function onUnlinkFromLibrary(
    values: { [key: string]: any },
    name: string,
  ) {
    if (data?.sourceWithType?.source == null) {
      enqueueSnackbar("Cannot unlink source.", { variant: "error" });
      console.error("no source found when requesting source and type");
      return;
    }

    /**
     * Copy source except name and ID
     */
    const updated = new BPResourceConfiguration({
      type: data.sourceWithType.source.spec.type,
      disabled: data.sourceWithType.source.spec.disabled,
      name: undefined,
      displayName: name,
    });
    updated.setParamsFromMap(values);
    updated.id = undefined;

    const updatedConfig = new BPConfiguration(configuration);

    updated.processors = updatedConfig.getSourceProcessors(trimmedSourceId);

    try {
      updatedConfig.replaceSource(updated, trimmedSourceId);
      const update = await updatedConfig.apply();
      if (update.status === UpdateStatus.INVALID) {
        console.error(update);
        throw new Error("failed to save source on configuration");
      }
      setEditing(false);
      refetchSource();
      enqueueSnackbar(
        `Successfully unlinked ${trimVersion(name)} from Library!`,
        {
          variant: "success",
          autoHideDuration: 3000,
        },
      );
    } catch (err) {
      enqueueSnackbar("Failed to update configuration with new Source", {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(err);
    }
  }

  function interceptOnTogglePause() {
    if (data?.sourceWithType?.source == null) {
      enqueueSnackbar("Cannot save source.", { variant: "error" });
      console.error("no source found when requesting source and type");
      return;
    }
    if (configurationCount?.configurationCount == null) {
      enqueueSnackbar("Cannot get configuration count.", { variant: "error" });
      console.error("Cannot get configuration count");
      return;
    }
    if (configurationCount.configurationCount > 1) {
      setEditResourceInUseWarningDialogOpen({
        open: true,
        formValues: {},
        function: "onToggle",
      });
    } else {
      onTogglePause();
    }
  }

  async function onTogglePause() {
    setEditResourceInUseWarningDialogOpen({
      open: false,
      formValues: {},
      function: null,
    });
    if (data?.sourceWithType?.source == null) {
      enqueueSnackbar("Cannot save source.", { variant: "error" });
      console.error("no source found when requesting source and type");
      return;
    }

    if (sourceId == null) {
      enqueueSnackbar("Cannot save source.", { variant: "error" });
      console.error("no source index found");
      return;
    }

    const updatedSource = new BPSource(data.sourceWithType.source);

    updatedSource.toggleDisabled();

    const action = updatedSource.spec.disabled ? "pause" : "resume";

    try {
      const update = await updatedSource.apply();
      if (update.status === UpdateStatus.INVALID) {
        console.error("Invalid Update: ", update);
        throw new Error(
          `failed to apply configuration, got status ${update.status}`,
        );
      }

      enqueueSnackbar(`Source ${action}d! 🎉`, { variant: "success" });

      setEditing(false);
      refetchConfiguration();
      refetchSource();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Failed to ${action} source.`, { variant: "error" });
    }
  }

  function handleWarnConfirm() {
    if (editResourceInUseWarningDialogOpen.function === "onSave") {
      onSave(editResourceInUseWarningDialogOpen.formValues);
    } else if (editResourceInUseWarningDialogOpen.function === "onToggle") {
      onTogglePause();
    } else {
      console.error("Provided function for warn dialog confirm is null");
    }
  }

  // Loading
  if (data === undefined || configurationCount === undefined) {
    return null;
  }

  if (
    data.sourceWithType.source == null ||
    data.sourceWithType.source.spec.type == null
  ) {
    enqueueSnackbar(`Failed to find Source or Type for Source ${name}`, {
      variant: "error",
    });

    return null;
  }

  return (
    <div>
      <ResourceCard
        name={trimVersion(name)}
        resourceNameField={data?.sourceWithType?.source?.metadata?.name}
        icon={data.sourceWithType?.sourceType?.metadata.icon}
        displayName={data.sourceWithType.sourceType?.metadata.displayName ?? ""}
        paused={data.sourceWithType.source?.spec.disabled}
        disabled={disabled || data.sourceWithType.source?.spec.disabled}
        onClick={() => setEditing(true)}
        dataTestID={`resource-card-${name}`}
      />
      <EditResourceDialog
        kind={Kind.Source}
        resourceTypeDisplayName={
          data?.sourceWithType?.sourceType?.metadata?.displayName ?? ""
        }
        resourceNameField={data?.sourceWithType?.source?.metadata?.name}
        displayName={data.sourceWithType.source?.metadata.displayName ?? ""}
        description={data.sourceWithType.sourceType?.metadata.description ?? ""}
        resourceDocLink={
          data.sourceWithType.sourceType?.metadata.resourceDocLink ?? ""
        }
        fullWidth
        maxWidth="sm"
        parameters={data.sourceWithType.source.spec.parameters ?? []}
        parameterDefinitions={
          data.sourceWithType.sourceType?.spec.parameters ?? []
        }
        open={editing}
        onClose={() => setEditing(false)}
        onCancel={() => setEditing(false)}
        onDelete={onDelete && (() => setDeleteOpen(true))}
        onSave={interceptOnSave}
        paused={data.sourceWithType.source?.spec.disabled}
        onTogglePause={interceptOnTogglePause}
        readOnly={readOnly || !hasPermission(Role.User, role)}
        showLibraryBookmark
        onUnlinkFromLibrary={onUnlinkFromLibrary}
        libraryResources={librarySources}
      />

      <EditResourceInUseWarningDialog
        resourceName={data.sourceWithType.source.metadata.name}
        resourceKind={Kind.Source}
        configurationCount={configurationCount?.configurationCount!}
        open={editResourceInUseWarningDialogOpen.open}
        onCancel={() =>
          setEditResourceInUseWarningDialogOpen({
            open: false,
            formValues: {},
            function: null,
          })
        }
        onConfirm={() => handleWarnConfirm()}
      />

      {onDelete && (
        <ConfirmDeleteResourceDialog
          open={confirmDeleteOpen}
          onClose={() => setDeleteOpen(false)}
          onCancel={() => setDeleteOpen(false)}
          onDelete={onDelete}
          action={"remove"}
        >
          <Typography>Are you sure you want to remove this source?</Typography>
        </ConfirmDeleteResourceDialog>
      )}
    </div>
  );
};
