import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormGroup,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EULAField } from "../../components/EULAField";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { acceptEula } from "../../utils/rest/accept-eula";
import styles from "./eula-required.module.scss";

const EulaRequiredPageContent: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  async function handleContinueClick() {
    try {
      await acceptEula();
      navigate("/overview");
    } catch (e) {
      enqueueSnackbar("Could not accept EULA", { variant: "error" });
    }
  }

  return (
    <Stack
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      className={styles.stack}
    >
      <Card classes={{ root: styles.card }}>
        <CardHeader title="EULA Required" />
        <CardContent>
          <FormGroup>
            <EULAField
              checked={checked}
              onChange={(_, checked) => setChecked(checked)}
            />
          </FormGroup>
        </CardContent>
        <CardActions className={styles.actions}>
          <Button
            disabled={!checked}
            onClick={handleContinueClick}
            variant="contained"
          >
            Continue
          </Button>
        </CardActions>
      </Card>
    </Stack>
  );
};

export const EulaRequiredPage = withRequireLogin(EulaRequiredPageContent);
