import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { isEqual } from "lodash";
import { memo, useState } from "react";
import { ChevronDown, ChevronUp, EditIcon, TrashIcon } from "../../Icons";
import { LinkButton } from "../../LinkButton";
import { useValidationContext } from "../ValidationContext";
import { ParamInputProps } from "./ParameterInput";
import { parameterHelperText } from "./utils";

export type RecombineConditionsValue = RecombineCondition[];

interface RecombineCondition {
  selector: string;
  selectorMatchExpression: string;
  recombineType: "firstEntry" | "lastEntry";
  recombineMatchExpression: string;
  recombineWith: string;
}

const EMPTY_ITEM: RecombineCondition = {
  selector: "",
  selectorMatchExpression: "",
  recombineType: "firstEntry",
  recombineMatchExpression: "",
  recombineWith: " ",
};

const EMPTY_VALUE: RecombineConditionsValue = [EMPTY_ITEM];

export const RecombineConditionsInput: React.FC<
  ParamInputProps<RecombineConditionsValue>
> = ({ definition, value: paramValue, readOnly, onValueChange }) => {
  const initValue =
    paramValue != null && paramValue.length > 0 ? paramValue : EMPTY_VALUE;
  const [controlValue, setControlValue] =
    useState<RecombineConditionsValue>(initValue);
  const [expanded, setExpanded] = useState(
    initValue != null && initValue.length > 0,
  );

  const { errors, touched, setError } = useValidationContext();

  function handleValueChange(newValue: RecombineConditionsValue) {
    if (isEqual(newValue, EMPTY_VALUE)) {
      onValueChange && onValueChange([]);
    } else {
      onValueChange && onValueChange(newValue);
    }
  }

  function handleAddConditionClick() {
    const newValue = [...controlValue, EMPTY_ITEM];
    setControlValue(newValue);
    handleValueChange(newValue);
    setError(definition.name, null);
  }

  function handleRemoveCondition(index: number) {
    const newValue = controlValue.filter((_, i) => i !== index);
    if (newValue.length === 0) {
      newValue.push(EMPTY_ITEM);
    }
    setControlValue(newValue);
    handleValueChange(newValue);
    setError(definition.name, null);
  }

  function handleItemChange(index: number, item: RecombineCondition) {
    const newValue = controlValue.map((v, i) => (i === index ? item : v));
    setControlValue(newValue);
    handleValueChange(newValue);
    setError(definition.name, null);
  }

  return (
    <Accordion
      sx={{
        border: 0,
      }}
      expanded={expanded}
      disableGutters
    >
      <AccordionSummary
        sx={{
          padding: 0,
        }}
      >
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems={"center"}
          spacing={0.5}
          width={"100%"}
        >
          <Stack flexGrow={1}>
            <FormLabel filled required={definition.required}>
              {definition.label}
            </FormLabel>
            {parameterHelperText(definition, errors, touched)}
          </Stack>
          <IconButton
            size={"small"}
            onClick={() => setExpanded(!expanded)}
            sx={{
              width: "24px",
              height: "24px",
            }}
          >
            {expanded ? (
              <ChevronUp />
            ) : readOnly ? (
              <ChevronDown />
            ) : (
              <EditIcon />
            )}
          </IconButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
        }}
      >
        <Stack>
          <Box marginTop="8px">
            {controlValue.map((item, index) => (
              <div key={`recombine-condition-item-container-${index}`}>
                <Box marginTop="16px" />
                <RecombineConditionItem
                  item={item}
                  index={index}
                  onRemoveCondition={handleRemoveCondition}
                  onItemChange={handleItemChange}
                  errored={errors[definition.name] != null}
                  readonly={readOnly || false}
                />
              </div>
            ))}
          </Box>

          {!readOnly && (
            <Stack
              direction="row-reverse"
              width="100%"
              marginTop="16px"
              paddingRight="36px"
            >
              <LinkButton onClick={handleAddConditionClick}>
                Add Condition
              </LinkButton>
            </Stack>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

type RecombineConditionItemProps = {
  item: RecombineCondition;
  index: number;
  onRemoveCondition: (index: number) => void;
  onItemChange(index: number, item: RecombineCondition): void;
  errored: boolean;
  readonly: boolean;
};

const RecombineConditionItem: React.FC<RecombineConditionItemProps> = memo(
  ({ item, index, onRemoveCondition, onItemChange, errored, readonly }) => {
    return (
      <Stack direction="row" spacing={1}>
        <Stack flexGrow={1} spacing={2}>
          <Divider />
          <Stack direction="row" spacing={2}>
            <Autocomplete
              value={item.selector}
              onInputChange={(_, value) =>
                onItemChange(index, { ...item, selector: value })
              }
              freeSolo
              fullWidth
              size="small"
              options={[
                'resource["k8s.pod.name"]',
                'resource["k8s.namespace.name"]',
              ]}
              disabled={readonly}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  error={errored && item.selector === ""}
                  label="Selector"
                  required
                  disabled={readonly}
                />
              )}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Selector Match Expression"
              value={item.selectorMatchExpression}
              error={errored && item.selectorMatchExpression === ""}
              onChange={(e) =>
                onItemChange(index, {
                  ...item,
                  selectorMatchExpression: e.target.value,
                })
              }
              fullWidth
              required
              disabled={readonly}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box width="135px">
              <FormControl fullWidth disabled={readonly}>
                <InputLabel id={`recombine-type-label-${index}`} required>
                  Recombine Type
                </InputLabel>
                <Select
                  labelId={`recombine-type-label-${index}`}
                  label="Recombine Type"
                  size="small"
                  value={item.recombineType}
                  onChange={(e) =>
                    onItemChange(index, {
                      ...item,
                      recombineType: e.target
                        .value as RecombineCondition["recombineType"],
                    })
                  }
                >
                  <MenuItem value="firstEntry">First Entry</MenuItem>
                  <MenuItem value="lastEntry">Last Entry</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box width="228px">
              <FormControl fullWidth disabled={readonly}>
                <InputLabel id={`recombine-with-label-${index}`}>
                  Recombine With
                </InputLabel>
                <Select
                  labelId={`recombine-with-label-${index}`}
                  label="Recombine With"
                  size="small"
                  value={item.recombineWith}
                  onChange={(e) =>
                    onItemChange(index, {
                      ...item,
                      recombineWith: e.target.value,
                    })
                  }
                >
                  <MenuItem value=" ">Single Space</MenuItem>
                  <MenuItem value="\n">New Line</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box flexGrow={1}>
              <TextField
                size="small"
                variant="outlined"
                label="Recombine Match Expression"
                value={item.recombineMatchExpression}
                error={errored && item.recombineMatchExpression === ""}
                onChange={(e) =>
                  onItemChange(index, {
                    ...item,
                    recombineMatchExpression: e.target.value,
                  })
                }
                fullWidth
                required
                disabled={readonly}
              />
            </Box>
          </Stack>
        </Stack>

        <Stack paddingTop="20px" width="28px">
          {!readonly && (
            <IconButton size="small" onClick={() => onRemoveCondition(index)}>
              <TrashIcon width={18} height={18} />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  },
);
