import { createContext, useContext, useEffect, useState } from "react";
import { useNodes, Node } from "reactflow";
import { Node as GraphNode } from "../../../graphql/generated";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { BPConfiguration } from "../../../utils/classes";
import { ComponentType, typeFrom } from "../../../utils/classes/component-type";
import { findResource } from "../../../utils/classes/configuration";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { AttributeName, V2Config, V2NodeData } from "../types";

interface RoutingContextProps {
  readOnly: boolean;
  configuration: V2Config;
}

type ComponentInfo = {
  componentPath: string;
  componentType: ComponentType;
};

interface RoutingContextValue {
  onRouteButtonClick: (
    componentType: ComponentType,
    componentPath: string,
  ) => void;
  // a list of component paths that can be connected to the currently
  // selected component.
  hasAvailableConnections(componentPath: string): boolean;
  connectableComponentPaths: string[];
  // a callback used to connect the a component to the currently selected
  onConnect: (componentPath: string) => Promise<void>;
  // a flag to indicate if the graph is in read-only mode
  readOnly: boolean;
  onEdgeDelete(fromNode: string, toNode: string): void;
}

const defaultValue: RoutingContextValue = {
  hasAvailableConnections: () => false,
  onRouteButtonClick: () => {},
  onConnect: async () => {},
  connectableComponentPaths: [],
  readOnly: true,
  onEdgeDelete: () => {},
};

export const routingContext = createContext<RoutingContextValue>(defaultValue);

/**
 * RoutingContext provides the callbacks needed for routing in the PipelineGraph
 */
export const RoutingContextProvider: React.FC<RoutingContextProps> = ({
  children,
  readOnly,
  configuration,
}) => {
  const [connectingComponent, setConnectingComponent] =
    useState<ComponentInfo | null>(null);
  const [connectableComponentPaths, setConnectableComponentPaths] = useState<
    string[]
  >([]);
  const { graph, refetchConfiguration } = useV2PipelineGraph();
  const { selectedTelemetryType } = useV2PipelineGraph();
  const nodes = useNodes<V2NodeData>();

  // Add event listener to exit the 'connecting' mode.
  useEffect(() => {
    function handleEscape(e: KeyboardEvent) {
      if (e.key === "Escape" && connectableComponentPaths.length > 0) {
        setConnectableComponentPaths([]);
        setConnectingComponent(null);
      }
    }
    document.addEventListener("keydown", handleEscape);

    return () => document.removeEventListener("keydown", handleEscape);
  }, [connectableComponentPaths.length]);

  // Clear the connecting component when telemetry type changes
  useEffect(() => {
    setConnectingComponent(null);
    setConnectableComponentPaths([]);
  }, [selectedTelemetryType]);

  function handleHighlightConnectable(
    componentType: ComponentType,
    componentPath: string,
  ) {
    if (!configuration) throw new Error("Configuration is not defined");
    const connectingRC = findResource(configuration, componentPath);
    if (!connectingRC)
      throw new Error(`Cannot find component with path ${componentPath}`);
    const connectingNode = nodes.find(
      (n) => n.data.attributes[AttributeName.ComponentPath] === componentPath,
    );
    if (!connectingNode)
      throw new Error(`Cannot find node with path ${componentPath}`);

    const targetsAndIntermediates: GraphNode[] = [
      ...(configuration?.graph?.targets ?? []),
      ...(configuration?.graph?.intermediates ?? []),
    ];
    if (targetsAndIntermediates.length === 0) return;

    setConnectingComponent({ componentType, componentPath });

    const connectable: string[] = [];
    for (const target of targetsAndIntermediates) {
      if (
        isConnectable(
          componentPath,
          target,
          selectedTelemetryType,
          connectingNode.data.connectedNodesAndEdges,
          configuration,
        )
      ) {
        connectable.push(target.attributes[AttributeName.ComponentPath]);
      }
    }

    setConnectableComponentPaths(connectable);
  }

  async function handleDeleteEdge(fromNode: string, toNode: string) {
    if (!graph) return;

    const source = graph.findNode(fromNode);
    const target = graph.findNode(toNode);

    if (!source || !target) return;
    const cfg = new BPConfiguration(configuration);
    cfg.removeComponentPathFromRC(
      source.attributes[AttributeName.ComponentPath],
      target.attributes[AttributeName.ComponentPath],
      selectedTelemetryType,
    );

    await cfg.apply();
    refetchConfiguration();
  }

  async function connectComponentRoute(componentPath: string) {
    if (connectingComponent == null || configuration == null) return;

    const cfg = new BPConfiguration(configuration);
    cfg.addComponentRoute(
      connectingComponent.componentPath,
      componentPath,
      selectedTelemetryType,
    );
    await cfg.apply();
    refetchConfiguration();

    setConnectingComponent(null);
    setConnectableComponentPaths([]);
  }

  function hasAvailableConnections(componentPath: string): boolean {
    return (
      !!configuration &&
      componentHasAvailableConnections(
        { componentPath, componentType: typeFrom(componentPath) },
        selectedTelemetryType,
        nodes,
        configuration,
      )
    );
  }

  return (
    <routingContext.Provider
      value={{
        onRouteButtonClick: handleHighlightConnectable,
        onConnect: connectComponentRoute,
        onEdgeDelete: handleDeleteEdge,
        hasAvailableConnections,
        connectableComponentPaths,
        readOnly,
      }}
    >
      {children}
    </routingContext.Provider>
  );
};

/**
 * isConnectable determines if a source resource configuration can connect to
 * a target on the graph.  It must support the telemetry type and not already
 * be connected.
 *
 * @param source ResourceConfiguration you want to test connection
 * @param target target node on the graph
 * @param telemetryType telemetry type to test
 * @returns
 */
function isConnectable(
  sourceComponentPath: string,
  target: GraphNode,
  telemetryType: string,
  connectedNodesAndEdges: string[],
  configuration: NonNullable<V2Config>,
) {
  // Can never connect to source or destination processors
  if (target.id.endsWith("/processors")) return false;

  const source = findResource(configuration, sourceComponentPath);
  if (!source) return false;

  const supportsType = hasPipelineTypeFlag(
    telemetryType,
    target.attributes[AttributeName.SupportedTypeFlags],
  );
  if (!supportsType) return false;

  const isSame =
    sourceComponentPath === target.attributes[AttributeName.ComponentPath];
  if (isSame) return false;

  const isCycle = connectedNodesAndEdges.includes(target.id);
  if (isCycle) return false;

  return true;
}

function componentHasAvailableConnections(
  componentInfo: ComponentInfo | null,
  selectedTelemetryType: string,
  nodes: Node<V2NodeData>[],
  configuration: NonNullable<V2Config>,
) {
  if (!componentInfo) return false;
  const node = nodes.find(
    (n) =>
      n.data.attributes[AttributeName.ComponentPath] ===
      componentInfo.componentPath,
  );
  if (!node) return false;

  const targetsAndIntermediates: GraphNode[] = [
    ...(configuration?.graph?.targets ?? []),
    ...(configuration?.graph?.intermediates ?? []),
  ];

  for (const t of targetsAndIntermediates) {
    if (
      isConnectable(
        componentInfo.componentPath,
        t,
        selectedTelemetryType,
        node.data.connectedNodesAndEdges,
        configuration,
      )
    ) {
      return true;
    }
  }
  return false;
}

export function useRouting(): RoutingContextValue {
  return useContext(routingContext);
}
