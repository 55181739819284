import { gql } from "@apollo/client";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  axisClasses,
  barElementClasses,
  BarPlot,
  ChartsAxisHighlight,
  ChartsGrid,
  chartsGridClasses,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import {
  ChartsLoadingOverlay,
  ChartsNoDataOverlay,
} from "@mui/x-charts/ChartsOverlay";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import {
  useGetAccountsQuery,
  useGetUsageGraphQuery,
} from "../../../graphql/generated";
import colors from "../../../styles/colors";
import styles from "./view-organization-page.module.scss";

gql`
  query GetUsageGraph($interval: String!, $accountIDs: [ID!]) {
    usageGraphData(interval: $interval, accountIDs: $accountIDs) {
      todayUsage
      averageUsage
      dataset {
        date
        usage
      }
    }
  }
  query GetAccounts {
    organizationInfo {
      accounts {
        metadata {
          id
          name
          version
          displayName
        }
      }
    }
  }
`;

enum UsageInterval {
  CurrentMonth = "month",
  NinetyDays = "90days",
}

export const OrganizationUsage: React.FC = () => {
  const [interval, setInterval] = useState<string>(UsageInterval.CurrentMonth);
  const [accountIDs, setAccountIDs] = useState<string[]>([]);
  const [highlightToday, setHighlightToday] = useState<boolean>(false);
  const [highlightAverage, setHighlightAverage] = useState<boolean>(false);

  const { data: orgInfo } = useGetAccountsQuery({
    onError: (err) => {
      console.error(err);
      enqueueSnackbar("Error getting accounts", {
        variant: "error",
        key: "get-accounts-error",
      });
    },
    onCompleted(data) {
      setAccountIDs(
        data.organizationInfo.accounts.map((act) => act.metadata.id),
      );
    },
  });

  const { data: usageData, loading } = useGetUsageGraphQuery({
    variables: {
      interval: interval,
      accountIDs: accountIDs,
    },
    // Wait for interval to be set by the context before fetching.
    skip: interval == null || accountIDs.length === 0,
    fetchPolicy: "network-only",
    onError(err) {
      enqueueSnackbar("Usage Graph unavailable.", { variant: "error" });
      console.error(err);
    },
  });

  const formatDate = (dateEpochMilli: number): string => {
    const date = new Date(dateEpochMilli);
    return `${date.getUTCMonth() + 1}/${date.getUTCDate()}`;
  };

  const formatUsage = (bytes: number | null): string => {
    if (bytes === null || bytes === 0) return "0 B";

    const units = ["B", "KB", "MB", "GB", "TB"];
    const unitIndex = Math.floor(Math.log(bytes) / Math.log(1000));
    const formattedValue = (bytes / Math.pow(1000, unitIndex)).toFixed(2);

    return `${formattedValue} ${units[unitIndex]}`;
  };

  const formatUsageTrimmed = (bytes: number | null): string => {
    let result = formatUsage(bytes);
    while (result.length > 7) {
      let val = result.split(" ").at(0);
      const units = result.split(" ").at(1);
      val = val?.substring(0, val.length - 1);
      if (val?.at(val.length - 1) === ".") {
        val = val?.substring(0, val.length - 1);
      }
      result = `${val} ${units}`;
    }
    return result;
  };

  const formatUsageYAxis = (bytes: number | null): string => {
    if (bytes === null || bytes === 0) return "0 B";

    const units = ["B", "KB", "MB", "GB", "TB"];
    const unitIndex = Math.floor(Math.log(bytes) / Math.log(1000));
    const formattedValue = bytes / Math.pow(1000, unitIndex);

    return `${formattedValue} ${units[unitIndex]}`;
  };

  const handleChange = (e: any) => {
    setAccountIDs(e.target.value);
  };

  const isAllSelected =
    (orgInfo?.organizationInfo.accounts.length ?? 0) > 0 &&
    accountIDs.length === orgInfo?.organizationInfo.accounts.length;

  const noData =
    accountIDs.length === 0 ||
    (usageData?.usageGraphData.dataset ?? []).length === 0;

  return (
    <Card className={styles.usage}>
      <CardContent>
        <Stack direction="row-reverse" spacing={1}>
          <TextField
            value={interval}
            onChange={(e) => {
              setInterval(e.target.value);
            }}
            style={{ minWidth: 150 }}
            size="small"
            label="Time"
            name="time"
            select
          >
            {Object.entries({
              "Current Month": UsageInterval.CurrentMonth,
              "Last 90 Days": UsageInterval.NinetyDays,
            }).map(([key, val]) => (
              <MenuItem key={key} value={val}>
                {key}
              </MenuItem>
            ))}
          </TextField>

          <FormControl>
            <InputLabel>Projects</InputLabel>
            <Select
              style={{ minWidth: 150 }}
              size="small"
              multiple
              value={accountIDs}
              displayEmpty={true}
              onChange={handleChange}
              input={<OutlinedInput label="Projects" />}
              renderValue={(acts) =>
                isAllSelected ? (
                  <Typography>All</Typography>
                ) : acts.length === 0 ? (
                  <Typography>None</Typography>
                ) : (
                  <Typography>Selected ({acts.length})</Typography>
                )
              }
            >
              {orgInfo?.organizationInfo.accounts.map((act) => (
                <MenuItem key={act.metadata.id} value={act.metadata.id}>
                  <Checkbox checked={accountIDs.includes(act.metadata.id)} />
                  {act.metadata.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ flexGrow: 1 }} />
          <Typography fontSize={26}>Usage</Typography>
        </Stack>
        <Stack direction="row" spacing={3} marginLeft={1} marginTop={3}>
          <Stack direction="column" spacing={3} paddingTop="5px">
            <Stack
              width={100}
              marginRight="8px"
              marginBottom="8px"
              justifyContent="center"
              alignItems="center"
            >
              <Card
                classes={{ root: styles.card }}
                onMouseEnter={() => setHighlightToday(true)}
                onMouseLeave={() => setHighlightToday(false)}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography fontSize={22}>
                    {noData
                      ? ""
                      : highlightToday
                        ? formatUsage(usageData?.usageGraphData.todayUsage ?? 0)
                        : formatUsageTrimmed(
                            usageData?.usageGraphData.todayUsage ?? 0,
                          )}
                  </Typography>
                )}
              </Card>
              <Typography
                fontWeight={600}
                marginBottom="4px"
                fontSize={14}
                noWrap
              >
                Today
              </Typography>
            </Stack>
            <Stack
              width={100}
              marginRight="8px"
              marginBottom="8px"
              justifyContent="center"
              alignItems="center"
              height={"auto"}
            >
              <Card
                classes={{ root: styles.card }}
                onMouseEnter={() => setHighlightAverage(true)}
                onMouseLeave={() => setHighlightAverage(false)}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography
                    color={
                      (usageData?.usageGraphData.averageUsage ?? 0) > 0
                        ? colors.pixelPointBlue
                        : colors.black
                    }
                    fontSize={22}
                  >
                    {noData
                      ? ""
                      : highlightAverage
                        ? formatUsage(
                            usageData?.usageGraphData.averageUsage ?? 0,
                          )
                        : formatUsageTrimmed(
                            usageData?.usageGraphData.averageUsage ?? 0,
                          )}
                  </Typography>
                )}
              </Card>
              <Typography
                fontWeight={600}
                marginBottom="4px"
                fontSize={14}
                noWrap
              >
                Daily Average
              </Typography>
            </Stack>
          </Stack>
          <ResponsiveChartContainer
            dataset={usageData?.usageGraphData.dataset ?? []}
            height={400}
            colors={[colors.pixelPointBlue]}
            sx={{
              padding: "5px",
              paddingLeft: "0px",
              [`.${barElementClasses.root}`]: {
                fill: colors.pixelPointBlueMediumHover,
                strokeWidth: 1,
              },
              [`.MuiBarElement-series-1`]: {
                stroke: colors.pixelPointBlue,
              },
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  strokeWidth: 1,
                },
              },
            }}
            series={[
              {
                dataKey: "usage",
                valueFormatter: formatUsage,
                type: "bar",
                id: 1,
              },
            ]}
            yAxis={[{ valueFormatter: formatUsageYAxis, min: 0 }]}
            xAxis={[
              {
                dataKey: "date",
                valueFormatter: (date, context) =>
                  context.location === "tooltip"
                    ? formatDate(date) + " UTC"
                    : formatDate(date),
                scaleType: "band",
              },
            ]}
            margin={{ top: 5, bottom: 50, left: 70, right: 0 }}
          >
            {loading ? (
              <ChartsLoadingOverlay />
            ) : noData ? (
              <ChartsNoDataOverlay />
            ) : (
              <>
                <ChartsGrid
                  horizontal
                  sx={{
                    [`& .${chartsGridClasses.line}`]: {
                      strokeDasharray: "7 3",
                    },
                  }}
                />
                <ChartsAxisHighlight x="band" />
                <BarPlot />
                {usageData?.usageGraphData.averageUsage !== undefined &&
                  usageData?.usageGraphData.averageUsage > 0 && (
                    <ChartsReferenceLine
                      y={usageData?.usageGraphData.averageUsage}
                      lineStyle={{
                        strokeWidth: highlightAverage ? 2 : 1,
                        stroke: colors.pixelPointBlue,
                        strokeDasharray: "7 3",
                      }}
                      labelAlign="end"
                      spacing={{ x: -40, y: -6 }}
                    />
                  )}
                <ChartsXAxis />
                <ChartsYAxis />
                <ChartsTooltip
                  slotProps={{
                    popper: {
                      sx: {
                        "& .MuiChartsTooltip-valueCell": {
                          paddingLeft: "16px !important",
                        },
                        "& .MuiChartsTooltip-markCell": {
                          display: "none",
                        },
                        "& .MuiChartsTooltip-labelCell": {
                          display: "none",
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </ResponsiveChartContainer>
        </Stack>
      </CardContent>
    </Card>
  );
};
