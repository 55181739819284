import { ParameterizedResource } from "../../graphql/generated";
import { BPBaseResource } from "./base-resource";

export class BPParameterizedResource
  extends BPBaseResource
  implements ParameterizedResource
{
  __typename?: "ParameterizedResource" | undefined;

  constructor(r: ParameterizedResource) {
    super();
    this.kind = r.kind;
    this.metadata = r.metadata;
    this.spec = r.spec;
  }
}

export function wrapResource(
  r: ParameterizedResource,
): BPParameterizedResource {
  return new BPParameterizedResource(r);
}
