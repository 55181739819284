import { Autocomplete, TextField } from "@mui/material";
import { isFunction } from "lodash";
import { memo } from "react";
import {
  DataPoint,
  Log,
  Span,
  useSnapshot,
} from "../../SnapShotConsole/SnapshotContext";
import { useValidationContext } from "../ValidationContext";
import { validateOTTLFields } from "../validation-functions";
import { ConditionMatch } from "./ConditionInput";
import { getFieldKeyOptions } from "./OTTLFieldInput";
import { ParamInputProps } from "./ParameterInput";
import { parameterHelperText } from "./utils";

interface OTTLFieldsInputProps extends ParamInputProps<string[]> {
  getOptions?: (
    match: ConditionMatch,
    logs: Log[],
    metrics: DataPoint[],
    traces: Span[],
    processedLogs: Log[],
    processedMetrics: DataPoint[],
    processedTraces: Span[],
  ) => string[];
}

const OTTLFieldsInputComponent: React.FC<OTTLFieldsInputProps> = ({
  getOptions = getFieldKeyOptions,
  definition,
  value,
  readOnly,
  onValueChange,
}) => {
  const { errors, touched, touch, setError } = useValidationContext();
  const {
    logs,
    metrics,
    traces,
    processedLogs,
    processedMetrics,
    processedTraces,
  } = useSnapshot();

  // handleAutocompleteValueChange is called whenever a pill is created or deleted. Represents the string[] value of the input parameter.
  function handleAutocompleteValueChange(
    _e: React.SyntheticEvent,
    newValue: string[],
  ) {
    if (!touched[definition.name]) {
      touch(definition.name);
    }
    isFunction(onValueChange) && onValueChange(newValue || "");
    setError(
      definition.name,
      validateOTTLFields(newValue, definition.required),
    );
  }

  return (
    <Autocomplete
      sx={{ flexGrow: 1 }}
      freeSolo
      multiple
      disableCloseOnSelect
      options={getOptions(
        definition.options?.ottlContext as ConditionMatch,
        logs,
        metrics,
        traces,
        processedLogs,
        processedMetrics,
        processedTraces,
        false,
      )}
      slotProps={{
        popper: {
          placement: "bottom-start",
          style: {
            width: "auto",
            left: "0px",
          },
        },
      }}
      value={value}
      disabled={readOnly}
      onChange={handleAutocompleteValueChange}
      ChipProps={{
        size: "small",
        variant: "outlined",
        sx: { margin: "2px !important" },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          slotProps={{
            input: params.InputProps,
            formHelperText: { sx: { paddingLeft: "-2px" } },
          }}
          value={value || ""}
          disabled={readOnly}
          name={definition.name}
          fullWidth
          size="small"
          label={definition.label}
          helperText={parameterHelperText(definition, errors, touched)}
          required={definition.required}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      )}
    />
  );
};

export const OTTLFieldsInput = memo(OTTLFieldsInputComponent);
