import { Stack } from "@mui/material";
import { useState } from "react";
import { ProcessorTelemetrySwitcher } from "../ProcessorTelemetrySwitcher/ProcessorTelemetrySwitcher";
import { SnapshotConsole } from "../SnapShotConsole/SnapShotConsole";
import { SnapShotControls } from "../SnapShotConsole/SnapShotControls";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";
import styles from "./processor-dialog.module.scss";

export const SnapshotSection: React.FC<{
  readOnly: boolean;
}> = ({ children, readOnly }) => {
  const { logs, metrics, traces, footer } = useSnapshot();
  const [workingQuery, setWorkingQuery] = useState<string>("");

  return (
    <Stack
      direction="row"
      width="100%"
      height="calc(100vh - 175px)"
      minHeight={"700px"}
      spacing={2}
      padding={2}
      paddingRight={4}
    >
      <div className={styles["snapshot-container"]}>
        <Stack spacing={2} height="100%">
          <SnapShotControls
            workingQuery={workingQuery}
            setWorkingQuery={setWorkingQuery}
          />
          <SnapshotConsole
            logs={logs}
            metrics={metrics}
            traces={traces}
            footer={footer}
            readOnly={readOnly}
          />
        </Stack>
      </div>
      <Stack flexGrow={0} height="100%" spacing={2}>
        <ProcessorTelemetrySwitcher setWorkingQuery={setWorkingQuery} />

        <div className={styles["form-container"]}>{children}</div>
      </Stack>
    </Stack>
  );
};
