import { Backdrop, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  GetResourceTypeQuery,
  GetResourceWithTypeQuery,
  Kind,
  ParameterizedResource,
  useGetResourcesLazyQuery,
  useGetResourceTypeQuery,
  useGetResourceWithTypeQuery,
} from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { resourceTypeOfKind } from "../../utils/classes/component-type";
import {
  saveComponent,
  saveComponentToLibrary,
  unlinkComponentFromLibrary,
} from "../../utils/forms/resource-manager";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import { FormValues } from "../ResourceConfigForm";
import { DialogResource } from "../ResourceDialog";
import { EditResourceDialog } from "../ResourceDialog/EditResourceDialog";
import { useComponentDialog } from "./ComponentDialogContext";

// TODO: move to shared-queries?
type ResourceType =
  GetResourceWithTypeQuery["resourceWithType"]["resourceType"] &
    GetResourceTypeQuery["resourceType"];
type Resource = GetResourceWithTypeQuery["resourceWithType"]["resource"];

export interface ConnectorDialogProps {
  configuration?: MinimumRequiredConfig;
  onDialogClose?: () => void;
  readOnly?: boolean;
}

export const ConnectorDialog: React.FC<ConnectorDialogProps> = ({
  configuration,
  onDialogClose,
  readOnly,
}) => {
  const {
    editingKind,
    editingComponent,
    editComponentOpen,
    closeComponentDialog,
  } = useComponentDialog();

  const { enqueueSnackbar } = useSnackbar();

  const name = editingComponent?.name ?? "";
  const typeName = editingComponent?.type ?? "";

  const [resource, setResource] = useState<Resource | null>(null);
  const [resourceType, setResourceType] = useState<ResourceType | null>(null);

  const [libraryResources, setLibraryResources] = useState<DialogResource[]>();

  useGetResourceWithTypeQuery({
    variables: { name, kind: editingKind! },
    skip: editingComponent == null || editingComponent.isInline(),
    onCompleted: ({ resourceWithType }) => {
      const { resource, resourceType } = resourceWithType;
      setResource(resource);
      setResourceType(resourceType);
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("Failed to fetch ConnectorType", {
        variant: "error",
      });
    },
  });

  useGetResourceTypeQuery({
    variables: { name: typeName, kind: resourceTypeOfKind(editingKind!) },
    skip: editingComponent == null || !editingComponent.isInline(),
    onCompleted: ({ resourceType }) => {
      setResourceType(resourceType);
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("Failed to fetch ConnectorType", {
        variant: "error",
      });
    },
  });

  const [fetchLibraryResources] = useGetResourcesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLibraryResources(
        data.resources.map((r) => ({
          metadata: {
            name: r.metadata.name,
          },
          spec: r.spec,
        })),
      );
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("Failed to fetch Library Connectors", {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (editComponentOpen && editingKind != null) {
      setLibraryResources(undefined);
      fetchLibraryResources({ variables: { kind: editingKind } });
    }
  }, [editComponentOpen, editingKind, fetchLibraryResources]);

  async function onAddToLibrary(formValues: FormValues, name: string) {
    await saveComponentToLibrary({
      configuration,
      component: editingComponent!,
      kind: editingKind!,
      formValues,
      name,
      enqueueSnackbar,
      onSuccess: ({ reference }) => {
        closeComponentDialog();
        onDialogClose?.();
      },
    });
  }

  async function onUnlinkFromLibrary(formValues: FormValues, name: string) {
    await unlinkComponentFromLibrary({
      configuration,
      component: editingComponent!,
      resource: resource!,
      kind: editingKind!,
      formValues,
      name,
      enqueueSnackbar,
      onSuccess: ({ inline }) => {
        closeComponentDialog();
        onDialogClose?.();
      },
    });
  }

  function onClose() {
    setResource(null);
    setResourceType(null);
    setLibraryResources(undefined);
    closeComponentDialog();
  }

  function onSave(formValues: FormValues) {
    saveComponent({
      configuration,
      component: editingComponent!,
      resource: resource ?? undefined,
      kind: editingKind!,
      formValues,
      enqueueSnackbar,
      onSuccess: ({ updated }) => {
        closeComponentDialog();
        onDialogClose?.();
      },
    });
  }

  if (
    editingComponent == null ||
    resourceType == null ||
    libraryResources == null
  ) {
    if (editComponentOpen) {
      return (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    return null;
  }

  const commonProps = {
    resourceType,
    component: editingComponent,
    readOnly,
    open: editComponentOpen,
    onSave,
    onDelete: undefined,
    onClose,
    onCancel: onClose,
    onAddToLibrary,
    onUnlinkFromLibrary,
    libraryResources,
  };

  if (editingComponent.isInline()) {
    return <InlineComponentDialog {...commonProps} kind={editingKind!} />;
  } else if (resource != null) {
    return (
      <LibraryComponentDialog
        {...commonProps}
        kind={editingKind!}
        resource={resource!}
      />
    );
  }
  return null;
};

// ----------------------------------------------------------------------
interface BaseComponentDialogProps {
  kind: Kind.Source | Kind.Destination | Kind.Processor | Kind.Connector;
  resourceType: ResourceType;
  readOnly?: boolean;
  open: boolean;

  // handlers
  onSave: (values: { [key: string]: any }) => void;
  onDelete?: () => void;
  onCancel: () => void;
  onClose: () => void;

  // pause
  paused?: boolean;
  onTogglePause?: () => void;

  // library
  onAddToLibrary?: (values: { [key: string]: any }, name: string) => void;
  onUnlinkFromLibrary?: (values: { [key: string]: any }, name: string) => void;
  libraryResources?: DialogResource[];
}

// ----------------------------------------------------------------------

interface InlineComponentDialogProps extends BaseComponentDialogProps {
  component: BPResourceConfiguration;
}

const InlineComponentDialog: React.FC<InlineComponentDialogProps> = ({
  kind,
  resourceType,
  component,
  readOnly,
  open,
  onSave,
  onDelete,
  onClose,
  paused,
  onTogglePause,
  onAddToLibrary,
  onUnlinkFromLibrary,
  libraryResources,
}) => {
  return (
    <EditResourceDialog
      kind={kind}
      displayName={component.displayName ?? ""}
      resourceTypeDisplayName={resourceType?.metadata.displayName ?? ""}
      description={resourceType?.metadata.description ?? ""}
      additionalInfo={resourceType?.metadata.additionalInfo}
      resourceDocLink={resourceType?.metadata.resourceDocLink ?? ""}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onClose}
      parameters={component.parameters ?? []}
      parameterDefinitions={resourceType?.spec.parameters ?? []}
      open={open}
      onClose={onClose}
      stability={resourceType?.metadata.stability ?? undefined}
      readOnly={readOnly}
      paused={paused}
      onTogglePause={onTogglePause}
      showLibraryBookmark={
        onAddToLibrary != null && onUnlinkFromLibrary != null
      }
      onAddToLibrary={onAddToLibrary}
      onUnlinkFromLibrary={onUnlinkFromLibrary}
      libraryResources={libraryResources}
    />
  );
};

// ----------------------------------------------------------------------
interface LibraryComponentDialogProps extends BaseComponentDialogProps {
  component: BPResourceConfiguration;
  resource: ParameterizedResource;
}

const LibraryComponentDialog: React.FC<LibraryComponentDialogProps> = ({
  kind,
  resourceType,
  component,
  resource,
  readOnly,
  open,
  onSave,
  onDelete,
  onClose,
  paused,
  onTogglePause,
  onAddToLibrary,
  onUnlinkFromLibrary,
  libraryResources,
}) => {
  return (
    <EditResourceDialog
      kind={kind}
      displayName={resource.metadata.displayName ?? ""}
      resourceTypeDisplayName={resourceType?.metadata.displayName ?? ""}
      resourceNameField={resource.metadata.name ?? ""}
      description={resourceType?.metadata.description ?? ""}
      additionalInfo={resourceType?.metadata.additionalInfo}
      resourceDocLink={resourceType?.metadata.resourceDocLink ?? ""}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onClose}
      parameters={resource.spec.parameters ?? []}
      parameterDefinitions={resourceType?.spec.parameters ?? []}
      open={open}
      onClose={onClose}
      stability={resourceType?.metadata.stability ?? undefined}
      readOnly={readOnly}
      paused={paused}
      onTogglePause={onTogglePause}
      showLibraryBookmark={
        onAddToLibrary != null && onUnlinkFromLibrary != null
      }
      onAddToLibrary={onAddToLibrary}
      onUnlinkFromLibrary={onUnlinkFromLibrary}
      libraryResources={libraryResources}
    />
  );
};
