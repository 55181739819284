import {
  FormHelperText,
  Stack,
  IconButton,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { cloneDeep, isEmpty } from "lodash";
import { useState, memo } from "react";
import {
  Suggestion,
  useGetLabelSuggestionsQuery,
} from "../../../graphql/generated";
import { TrashIcon, PlusCircleIcon } from "../../Icons";
import { filterSuggestionsFn } from "../../LabelEditor/LabelEditor";
import { SearchBar } from "../../SearchBar";
import { useValidationContext } from "../ValidationContext";
import { ParamInputProps } from "./ParameterInput";

export interface RolloutOptionsStage {
  name: string;
  labels: { [key: string]: string };
}

const RolloutStagesInputComponent: React.FC<
  ParamInputProps<RolloutOptionsStage[]>
> = ({ definition, value, readOnly, onValueChange }) => {
  const { errors, touched, touch } = useValidationContext();

  const [suggestionsQuery, setSuggestionsQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const { refetch } = useGetLabelSuggestionsQuery({
    variables: { query: suggestionsQuery },
    onCompleted: (data) => {
      setSuggestions(data.labelSuggestions.suggestions);
      setSuggestionsQuery(data.labelSuggestions.query);
    },
    onError(e) {
      console.error(e);
    },
  });

  function handleSearchBarChange(query: string) {
    refetch({ query });
  }

  const [controlValue, setControlValue] = useState<RolloutOptionsStage[]>(
    value || [{ name: "", labels: {} }],
  );

  const onChangeStageInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    row: number,
  ) => {
    let newControlValue = cloneDeep(controlValue);
    newControlValue[row].name = e.target.value;
    setControlValue(newControlValue);
    onValueChange && onValueChange(newControlValue);
  };

  function handleBlur() {
    if (!touched[definition.name]) {
      touch(definition.name);
    }
  }

  function handleAddRow() {
    let newControlValue = cloneDeep(controlValue);
    newControlValue.push({ name: "", labels: {} });
    setControlValue(newControlValue);
    onValueChange && onValueChange(newControlValue);
  }

  function handleDeleteRow(row: number) {
    if (controlValue.length === 1) {
      return;
    }
    let newControlValue = cloneDeep(controlValue);
    newControlValue.splice(row, 1);
    setControlValue(newControlValue);
    onValueChange && onValueChange(newControlValue);
  }

  function handleAddLabel(v: string, row: number) {
    const [key, val] = v.split(":");
    let newControlValue = cloneDeep(controlValue);
    newControlValue[row].labels[key] = val;
    setControlValue(newControlValue);
    onValueChange && onValueChange(newControlValue);
  }

  function handleDeleteLabel(labelKey: string, row: number) {
    let newControlValue = cloneDeep(controlValue);
    delete newControlValue[row].labels[labelKey];
    setControlValue(newControlValue);
    onValueChange && onValueChange(newControlValue);
  }

  return (
    <>
      <Stack spacing={1} marginTop={3}>
        {controlValue.map((s: RolloutOptionsStage, index: number) => (
          <Stack key={`${definition.name}-row-${index}`} spacing={1}>
            <Stack direction="row" spacing={1}>
              <TextField
                autoFocus={index !== 0 && index === controlValue.length - 1}
                disabled={readOnly}
                id={`${definition.name}-${index}-stage-input`}
                key={`${definition.name}-${index}-stage-input`}
                data-testid={`${definition.name}-${index}-stage-input`}
                size="small"
                type="text"
                value={s.name}
                onChange={(e) => onChangeStageInput(e, index)}
                onBlur={handleBlur}
                sx={{ width: 200 }}
                label={`Stage ${index + 1}`}
                required
                helperText={
                  <>
                    {errors[definition.name] && s.name === "" && (
                      <>
                        <FormHelperText
                          sx={{ marginLeft: 0 }}
                          component="span"
                          error
                        >
                          Stage {index + 1} must have a name
                        </FormHelperText>
                        <br />
                      </>
                    )}
                    {errors[definition.name] && isEmpty(s.labels) && (
                      <>
                        <FormHelperText
                          sx={{ marginLeft: 0 }}
                          component="span"
                          error
                        >
                          Stage {index + 1} must have a label
                        </FormHelperText>
                        <br />
                      </>
                    )}
                  </>
                }
              />
              <Stack flex={1}>
                <SearchBar
                  suggestions={suggestions.filter(filterSuggestionsFn)}
                  suggestionQuery={suggestionsQuery}
                  placeholder="Add a label"
                  onQueryChange={handleSearchBarChange}
                  onValueSelect={(v) => handleAddLabel(v, index)}
                  clearOnValueSelect
                  limitOneToken
                  validateAsLabel
                  key={`${definition.name}-${index}-label-input`}
                  data-testid={`${definition.name}-${index}-label-input`}
                  readOnly={readOnly}
                  tall
                  labels={{
                    labelsToRender: s.labels,
                    labelsIndex: index,
                    onDeleteLabel: handleDeleteLabel,
                  }}
                />
              </Stack>
              <Stack>
                <IconButton
                  key={`${definition.name}-${index}-remove-button`}
                  data-testid={`${definition.name}-${index}-remove-button`}
                  size={"small"}
                  disabled={readOnly}
                  onClick={() => handleDeleteRow(index)}
                  style={{ position: "relative", top: "2px" }}
                >
                  <TrashIcon
                    key={`${definition.name}-${index}-remove-icon`}
                    width={20}
                    height={20}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Box marginLeft={1}>
        <Button
          disabled={readOnly}
          startIcon={<PlusCircleIcon />}
          onClick={handleAddRow}
        >
          Add Stage
        </Button>
      </Box>
    </>
  );
};

export const RolloutStagesInput = memo(RolloutStagesInputComponent);
