import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { createBrowserHistory } from "history";
import { useCallback, useState } from "react";
import { BindplaneVersion } from "../../components/BindplaneVersion";
import { useLogo } from "../../hooks/useLogo";
import lights from "../../lights.png";
import colors from "../../styles/colors";
import styles from "./login.module.scss";

type RedirectResponse = {
  url: string;
};

export const OIDCLogin: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = createBrowserHistory();
  const logo = useLogo(
    { width: 225, height: 60, className: styles.logo },
    "regular",
  );

  const getToken = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await fetch("/auth/oidc/login");

      const tokenResponse = (await resp.json()) as RedirectResponse;
      history.push(tokenResponse.url);
    } catch (e) {
      console.error("request failed", { e });
    } finally {
      setLoading(false);
    }
  }, [history, setLoading]);

  if (loading) {
    return (
      <Stack
        height="100vh"
        alignItems="center"
        justifyContent={"center"}
        bgcolor={colors.backgroundWhite}
      >
        <CircularProgress size={100} />
      </Stack>
    );
  }

  return (
    <>
      <div className={styles["login-page"]} data-testid="login-page">
        <img
          src={lights}
          alt="lights.png"
          width={"100%"}
          style={{
            position: "fixed",
            top: "-10rem",
            left: "-0.1rem",
          }}
        />
        <Stack alignItems={"center"} justifyContent={"center"}>
          {logo}
          <Card classes={{ root: styles.card }}>
            <CardContent>
              <Stack alignItems={"center"} justifyContent={"center"}>
                <Typography
                  justifyContent={"center"}
                  alignItems={"center"}
                  variant="h5"
                  fontWeight={600}
                >
                  Sign In
                </Typography>

                <LoadingButton
                  onClick={() => getToken()}
                  variant="contained"
                  data-testid="sign-in-button"
                  loading={loading}
                >
                  Sign In With OpenID Connect
                </LoadingButton>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </div>
      <footer>
        <BindplaneVersion />
      </footer>
    </>
  );
};
