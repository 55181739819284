import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Handle, Position } from "reactflow";
import colors from "../../styles/colors";
import { classes } from "../../utils/styles";
import { NoMaxWidthTooltip } from "../Custom/NoMaxWidthTooltip";
import { BookmarkIcon, PauseIcon } from "../Icons";
import styles from "./cards.module.scss";

interface ResourceCardProps {
  name: string;
  resourceNameField?: string;
  displayName?: string;
  icon?: string | null;
  altIcon?: React.ReactNode;
  paused?: boolean;
  disabled?: boolean;
  onClick: () => void;
  dataTestID?: string;
}

export const ResourceCard: React.FC<ResourceCardProps> = ({
  name,
  resourceNameField,
  displayName,
  icon,
  altIcon,
  paused,
  disabled,
  onClick,
  dataTestID,
}) => {
  const renderIcon = () => {
    if (icon) {
      return (
        <Box
          className={styles.icon}
          style={{ backgroundImage: `url(${icon})` }}
        />
      );
    }
    return altIcon;
  };

  return (
    <Box>
      <Card
        className={classes([styles["resource-card"]])}
        style={{ background: paused ? colors.lightGray : "" }}
        onClick={onClick}
      >
        <CardActionArea
          className={classes([
            styles.action,
            disabled ? styles.disabled : undefined,
            paused ? styles.paused : undefined,
          ])}
        >
          <CardContent data-testid={dataTestID}>
            <Handle type="source" position={Position.Right} />
            <Handle type="target" position={Position.Left} />
            <Stack
              spacing={0}
              width="100%"
              height="100%"
              justifyContent={"center"}
              alignItems={"center"}
            >
              {renderIcon()}
            </Stack>
            {paused && (
              <PauseIcon
                data-testid={`${dataTestID}-pause-icon`}
                className={styles["pause-icon"]}
              />
            )}
            {resourceNameField && (
              <BookmarkIcon
                fill={colors.pixelPointBlue}
                stroke={colors.pixelPointBlue}
                className={styles["bookmark-icon"]}
                data-testid={`${dataTestID}-bookmark-icon`}
                width={"18px"}
              />
            )}
          </CardContent>
        </CardActionArea>
      </Card>

      <Stack alignItems="center" justifyContent={"center"} direction={"row"}>
        <NoMaxWidthTooltip title={name}>
          <Typography
            paddingTop={1}
            className={classes([
              styles.title,
              disabled ? styles.disabled : undefined,
            ])}
          >
            {name}
          </Typography>
        </NoMaxWidthTooltip>
      </Stack>
      <Stack alignItems="center" justifyContent={"center"} direction={"row"}>
        {displayName && (
          <NoMaxWidthTooltip title={displayName}>
            <Typography
              className={classes([
                styles.description,
                disabled ? styles.disabled : undefined,
              ])}
            >
              {displayName}
            </Typography>
          </NoMaxWidthTooltip>
        )}
      </Stack>
    </Box>
  );
};
