import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isFunction } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { AGENT_SCRAPE_PERIODS } from "../../../components/MeasurementControlBar/MeasurementControlBar";
import { usePipelineGraph } from "../../../hooks/usePipelineGraph";
import { UpdateStatus } from "../../../types/resources";
import { BPConfiguration } from "../../../utils/classes";

interface Props extends DialogProps {
  onSuccess: () => void;
  readOnly?: boolean;
}

export const AdvancedConfigDialog: React.FC<Props> = ({
  onSuccess,
  readOnly,
  ...dialogProps
}) => {
  const { configuration, refetchConfiguration } = usePipelineGraph();

  const [measurementInterval, setMeasurementInterval] = useState<string>(
    configuration?.spec?.measurementInterval ?? "1m",
  );
  const [touched, setTouched] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  function clearState() {
    setTouched(false);
  }

  async function updateMeasurementInterval() {
    if (configuration == null) {
      throw new Error("No configuration data to apply.");
    }

    const updatedConfig = new BPConfiguration(configuration);
    if (measurementInterval == null) {
      throw new Error("No measurement interval to apply.");
    }
    updatedConfig.updateMeasurementInterval(measurementInterval);

    const update = await updatedConfig.apply();
    if (update.status === UpdateStatus.INVALID) {
      throw new Error("failed to update measurement interval.");
    }

    refetchConfiguration();
    onSuccess();
  }

  async function handleSave(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      await updateMeasurementInterval();

      enqueueSnackbar("Measurements interval updated successfully", {
        variant: "success",
      });

      // Call the provided onSuccess function
      onSuccess();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update Measurements interval", {
        variant: "error",
      });
    }
  }

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    if (!touched) {
      setTouched(true);
    }
    setMeasurementInterval(e.target.value);
  }

  const dirty =
    measurementInterval !== configuration?.spec?.measurementInterval;

  return (
    <Dialog
      {...dialogProps}
      TransitionProps={{
        onExited: clearState,
      }}
    >
      <DialogContent>
        <Typography variant="h6" marginBottom={2}>
          Advanced Configuration Options
        </Typography>
        <Typography>
          Here you can set the scrape interval for the agent's measurements.
        </Typography>
        <form onSubmit={handleSave}>
          <TextField
            value={measurementInterval}
            onChange={handleChange}
            size="small"
            label="Measurements Scrape Interval"
            name="interval"
            fullWidth
            margin="normal"
            required
            select
            onBlur={() => setTouched(true)}
            SelectProps={{ native: true }}
            disabled={readOnly}
          >
            {/* If the measurements interval is off, show it as an option - otherwise, it's hidden from the user */}
            {configuration?.spec?.measurementInterval === "off" ? (
              <option key="off" value="off">
                off
              </option>
            ) : null}
            {Object.entries(AGENT_SCRAPE_PERIODS).map(([p, label]) => (
              <option key={p} value={label}>
                {label}
              </option>
            ))}
          </TextField>

          <Stack
            direction="row"
            justifyContent="end"
            spacing={1}
            marginTop="8px"
          >
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                isFunction(dialogProps.onClose) &&
                  dialogProps.onClose({}, "backdropClick");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!dirty || readOnly}
              type="submit"
            >
              Save
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
