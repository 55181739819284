import { PipelineType, ResourceConfiguration } from "../../graphql/generated";
import {
  editParameterValue,
  equalsParameterValue,
  equalsTelemetryType,
  getParameterValue,
  isResourceType,
} from "../../utils/classes/resource-configuration";
import {
  Condition,
  ConditionInputValue,
  ConditionMatch,
  ConditionPath,
} from "../ResourceConfigForm/ParameterInput/ConditionInput";
import {
  SnapshotAction,
  SnapshotActionType,
} from "../SnapShotConsole/SnapShotConsole";
import { FieldType } from "../SnapShotConsole/types";

export interface ResourceEditor {
  match(p: ResourceConfiguration): boolean;
  update(p: ResourceConfiguration): boolean;
}

interface ProcessorEditor {
  addProcessor(type: string, params: Record<string, any>): void;
  editProcessor(editor: ResourceEditor): boolean;
}

interface ActionHandler {
  (action: SnapshotAction, editor: ProcessorEditor): void;
}

const actionHandlers: { [key in SnapshotActionType]?: ActionHandler } = {
  [SnapshotActionType.INCLUDE_METRIC]: filterByMetricName,
  [SnapshotActionType.EXCLUDE_METRIC]: filterByMetricName,
  [SnapshotActionType.INCLUDE_FIELD]: filterByField,
  [SnapshotActionType.EXCLUDE_FIELD]: filterByField,
  [SnapshotActionType.DELETE_FIELD]: deleteField,
  [SnapshotActionType.RENAME_FIELD]: renameField,
  [SnapshotActionType.COPY_OTTL]: copyOttl,
  [SnapshotActionType.COPY_VALUE]: copyValue,
  [SnapshotActionType.FILTER_SEVERITY_GTE]: filterSeverityGte,
  [SnapshotActionType.GROUP_BY_ATTRIBUTES]: groupByAttributes,
};

export function handleAction(action: SnapshotAction, editor: ProcessorEditor) {
  actionHandlers[action.type]?.(action, editor);
}

// ----------------------------------------------------------------------

function filterByMetricName(
  action: SnapshotAction,
  { addProcessor, editProcessor }: ProcessorEditor,
) {
  const { type, data } = action;
  const isIncludeMetric = type === SnapshotActionType.INCLUDE_METRIC;
  // if we already have an include, we want to add to it so that we get OR
  // semantics. two separate processors will have AND semantics and no metrics will
  // match both, so we'll get no results.
  if (isIncludeMetric) {
    const edited = editProcessor({
      match: (p) =>
        isResourceType(p, "filter_metric_name") &&
        equalsParameterValue(p, "action", "include") &&
        equalsParameterValue(p, "match_type", "strict"),
      update: (copy) => {
        const names = editParameterValue(
          copy,
          "metric_names",
          (current?: string[]) => addValueToList(current, data.name),
        );
        copy.displayName = `Include ${names.join(", ")}`;
        return true;
      },
    });
    if (edited) return;
  }

  addProcessor("filter_metric_name", {
    displayName: `${isIncludeMetric ? "Include" : "Exclude"} ${data.name}`,
    action: isIncludeMetric ? "include" : "exclude",
    match_type: "strict",
    metric_names: [data.name],
  });
}

// ----------------------------------------------------------------------

function filterByField(
  action: SnapshotAction,
  { addProcessor, editProcessor }: ProcessorEditor,
) {
  const { type, data } = action;
  let { key, value, datatype, fieldtype } = data;
  const isInclude = type === SnapshotActionType.INCLUDE_FIELD;

  let operator = datatype === "string" ? "Equals" : "==";

  // special case for metric.type to use ==
  if (fieldtype === "metric" && key === "type") {
    operator = "==";
  }

  const statement = {
    match: fieldtype ?? ConditionMatch.ATTRIBUTES,
    key,
    operator,
    value,
  };

  const ui = { operator: "", statements: [statement] };

  if (isInclude) {
    const edited = editProcessor({
      match: (p) => {
        // we can edit an existing processor if it's an include, is not raw OTTL, and
        // matches the same telemetry type
        if (
          isResourceType(p, "filter-by-condition") &&
          equalsParameterValue(p, "action", "include") &&
          typeof getParameterValue(p, "condition", "") === "object"
        ) {
          const current = getParameterValue(p, "telemetry_types", []);
          return (
            current?.length === 1 &&
            current[0] === telemetryTypesEnumValue(action.pipelineType)
          );
        }
        return false;
      },
      update: (copy) => {
        const result = editParameterValue(
          copy,
          "condition",
          (current?: ConditionInputValue | string) => {
            const condition = new Condition(current);
            // add an OR condition
            if (condition.addGroup(new ConditionPath([]))) {
              // overwrite the new blank statement with the new one from this action
              condition.updateStatement(new ConditionPath([]), (statement) => {
                statement.statements![1] = ui;
              });
            }
            return condition.value();
          },
        ) as ConditionInputValue;
        copy.displayName = `Include ${result.ottl} (${action.pipelineType})`;
        return true;
      },
    });
    if (edited) return;
  }

  // generate the ottl
  const condition = new Condition({ ottl: "", ui });

  const displayName = `${isInclude ? "Include" : "Exclude"} ${condition.ottl()} (${
    action.pipelineType
  })`;

  const params: any = {
    displayName,
    action: isInclude ? "include" : "exclude",
    telemetry_types: [telemetryTypesEnumValue(action.pipelineType)],
    condition: condition.value(),
  };

  addProcessor("filter-by-condition", params);
}

// ----------------------------------------------------------------------

const deleteFieldParamKeys: {
  [pipelineType: string]: {
    telemetryType: string;
    conditionKey: string;
    fieldKey: { [key: string]: string };
  };
} = {
  [PipelineType.Logs]: {
    telemetryType: "Logs",
    conditionKey: "condition",
    fieldKey: {
      [FieldType.Body]: "body_keys",
      [FieldType.Attribute]: "attributes",
      [FieldType.Resource]: "resource_attributes",
    },
  },
  [PipelineType.Metrics]: {
    telemetryType: "Metrics",
    conditionKey: "condition", // not metric_condition
    fieldKey: {
      [FieldType.Attribute]: "attributes",
      [FieldType.Resource]: "resource_attributes",
    },
  },
  [PipelineType.Traces]: {
    telemetryType: "Traces",
    conditionKey: "condition",
    fieldKey: {
      [FieldType.Attribute]: "attributes",
      [FieldType.Resource]: "resource_attributes",
    },
  },
};

function deleteField(
  action: SnapshotAction,
  { addProcessor, editProcessor }: ProcessorEditor,
) {
  const pk = deleteFieldParamKeys[action.pipelineType];

  const { key, fieldtype } = action.data;
  const edited = editProcessor({
    match: (p) =>
      isResourceType(p, "delete_fields_v2") &&
      equalsTelemetryType(p, pk.telemetryType) &&
      equalsParameterValue(p, pk.conditionKey, "true"),
    update: (copy) => {
      editParameterValue(copy, pk.fieldKey[fieldtype], (current?: string[]) =>
        addValueToList(current, key),
      );

      const allFields: string[] = [];
      for (const k of Object.values(pk.fieldKey)) {
        allFields.push(...(getParameterValue(copy, k, []) ?? []));
      }

      copy.displayName = `Delete ${allFields.join(", ")} (${
        action.pipelineType
      })`;
      return true;
    },
  });
  if (edited) return;

  const params: any = {
    displayName: `Delete ${key} (${action.pipelineType})`,
    telemetry_types: [pk.telemetryType],
    [pk.conditionKey]: "true",
    [pk.fieldKey[fieldtype]]: [key],
  };

  addProcessor("delete_fields_v2", params);
}

// ----------------------------------------------------------------------

const renameFieldParamKeys: {
  [pipelineType: string]: {
    telemetryType: string;
    conditionKey: string;
  };
} = {
  [PipelineType.Logs]: {
    telemetryType: "Logs",
    conditionKey: "condition",
  },
  [PipelineType.Metrics]: {
    telemetryType: "Metrics",
    conditionKey: "condition", // not metric_condition
  },
  [PipelineType.Traces]: {
    telemetryType: "Traces",
    conditionKey: "span_condition",
  },
};

function renameField(
  action: SnapshotAction,
  { addProcessor }: ProcessorEditor,
) {
  const { key, fieldtype } = action.data;
  const pk = renameFieldParamKeys[action.pipelineType];

  // prompt for a new name2
  const newKey = prompt(`New name for the ${key} field:`);

  const params: any = {
    displayName: `Rename ${key} => ${newKey} (${action.pipelineType})`,
    telemetry_types: [pk.telemetryType],
    [pk.conditionKey]: "true",
    fields: [
      {
        fieldType: fieldtype,
        fieldAction: "rename",
        key: key,
        value: newKey,
      },
    ],
  };
  addProcessor("rename_fields_v2", params);
}

// ----------------------------------------------------------------------

function copyOttl(action: SnapshotAction, editor: ProcessorEditor) {
  const { key, fieldtype } = action.data;
  let ottl: string;
  switch (fieldtype) {
    case FieldType.Attribute:
      ottl = `attributes["${key}"]`;
      break;

    case FieldType.Resource:
      ottl = `resource.attributes["${key}"]`;
      break;

    case FieldType.Metric:
      ottl = `metric.${key}`;
      break;

    default: // "body"
      ottl = key;
      break;
  }
  navigator.clipboard.writeText(ottl);
}

// ----------------------------------------------------------------------

function copyValue(action: SnapshotAction, editor: ProcessorEditor) {
  navigator.clipboard.writeText(action.data.value);
}

// ----------------------------------------------------------------------

export const filterSeverityOptions: Record<string, string> = {
  trace: "TRACE",
  debug: "DEBUG",
  info: "INFO",
  warning: "WARN",
  warn: "WARN",
  error: "ERROR",
  fatal: "FATAL",
};

function filterSeverityGte(
  action: SnapshotAction,
  { addProcessor }: ProcessorEditor,
) {
  const { severity } = action.data;
  const severityOption = filterSeverityOptions[severity];
  const params: any = {
    displayName: `Severity >= ${severityOption}`,
    severity: severityOption,
  };

  addProcessor("filter_severity", params);
}

// ----------------------------------------------------------------------

const groupByAttributesParamKeys: {
  [pipelineType: string]: {
    telemetryType: string;
    attributesKey: string;
  };
} = {
  [PipelineType.Logs]: {
    telemetryType: "Logs",
    attributesKey: "log_attributes",
  },
  [PipelineType.Metrics]: {
    telemetryType: "Metrics",
    attributesKey: "metric_attributes",
  },
  [PipelineType.Traces]: {
    telemetryType: "Traces",
    attributesKey: "trace_attributes",
  },
};

function groupByAttributes(
  action: SnapshotAction,
  { addProcessor, editProcessor }: ProcessorEditor,
) {
  const pk = groupByAttributesParamKeys[action.pipelineType];
  const { key } = action.data;

  const edited = editProcessor({
    match: (p) => isResourceType(p, "group_by_attributes"),
    update: (copy) => {
      editParameterValue(copy, pk.attributesKey, (current?: string[]) =>
        addValueToList(current, key),
      );

      const allFields = [];
      for (const [tt, params] of Object.entries(groupByAttributesParamKeys)) {
        const fields = getParameterValue(copy, params.attributesKey, []) ?? [];
        if (fields.length > 0) {
          allFields.push(`${fields.join(", ")} (${tt})`);
        }
      }

      copy.displayName = `Group by ${allFields.join(", ")}`;
      return true;
    },
  });
  if (edited) return;

  const params: any = {
    displayName: `Group by ${key} (${action.pipelineType})`,
    telemetry_types: [pk.telemetryType],
    [pk.attributesKey]: [key],
  };

  addProcessor("group_by_attributes", params);
}

// ----------------------------------------------------------------------
// helpers

function telemetryTypesEnumValue(pipelineType: PipelineType): string {
  switch (pipelineType) {
    case PipelineType.Logs:
      return "Logs";
    case PipelineType.Metrics:
      return "Metrics";
    case PipelineType.Traces:
      return "Traces";
    default:
      return "Logs";
  }
}

// add a value to a list, creating the list if it doesn't exist and doing nothing if the
// value is already in the list. returns the updated list.
function addValueToList(list: string[] | undefined, value: string): string[] {
  if (!list) {
    return [value];
  }
  if (list.includes(value)) {
    return list;
  }
  return [...list, value];
}
