import { Typography } from "@mui/material";
import { formatMetric } from "../../utils/graph/utils";
import { ArrowDownRightIcon, ArrowUpRightIcon } from "../Icons";
import { PipelineGraphMetricsData } from "../PipelineGraphV2/PipelineGraphMetricsData";
import { BPGraph, NodeId } from "./graph";
import styles from "../Cards/cards.module.scss";

export class BPGraphMetrics {
  constructor(
    private graph: BPGraph,
    private metricsData: PipelineGraphMetricsData,
    private pipelineType: string,
    private period: string,
  ) {}

  /**
   * The formatted inbound metric for a given route, e.g. 19.2 KB/m
   */
  getInboundMetricFormatted(nodeId: NodeId): string {
    const value = this.getInboundMetric(nodeId) ?? 0;
    return formatMetric({ value, unit: "B/s" }, this.period);
  }

  /**
   * The formatted outbound metric for a given route, e.g. 19.2 KB/m
   */
  getOutboundMetricFormatted(nodeId: NodeId, routeId: string = ""): string {
    const metrics = this.getOutboundMetrics(nodeId);
    const value = metrics[routeId] ?? 0;
    return formatMetric({ value, unit: "B/s" }, this.period);
  }

  /**
   * The percentage of the outbound metric for a given route vs the inbound metric.
   */
  getOutboundMetricPercentageFormatted(
    nodeId: NodeId,
    routeId: string = "",
  ): JSX.Element | undefined {
    const percentage = this.getOutboundMetricPercentage(nodeId, routeId);
    if (percentage == null || percentage === 100) {
      return undefined;
    }
    const higher = percentage > 100;
    const value = Math.abs(percentage - 100).toFixed(1);
    return (
      <Typography className={styles["processor-percentage"]}>
        {higher ? <ArrowUpRightIcon /> : <ArrowDownRightIcon />}
        {`${value}%`}
      </Typography>
    );
  }

  /**
   * The percentage of the outbound metric for a given route vs the inbound metric.
   * Returns a number between 0 and 100.
   */
  getOutboundMetricPercentage(
    nodeId: NodeId,
    routeId: string = "",
  ): number | undefined {
    const total = this.getInboundMetric(nodeId) ?? 0;
    const value = this.getOutboundMetrics(nodeId)[routeId] ?? 0;
    return total === 0 ? undefined : (value / total) * 100;
  }

  // ----------------------------------------------------------------------

  getInboundMetric(nodeId: NodeId): number | undefined {
    const edges = this.graph.getEdgesInbound(nodeId);
    const value = edges
      .filter((edge) => edge.attributes?.pipelineType === this.pipelineType)
      .reduce((acc, edge) => {
        const metric = this.metricsData.metric(
          edge?.attributes.metricID,
          this.pipelineType,
        );
        return acc + (metric?.value ?? 0);
      }, 0);
    return value;
  }

  getOutboundMetrics(id: NodeId): { [edgeId: string]: number } {
    const edges = this.graph.getEdgesOutbound(id);
    return edges
      .filter((edge) => edge.attributes?.pipelineType === this.pipelineType)
      .reduce((acc: { [edgeId: string]: number }, edge) => {
        const metric = this.metricsData.metric(
          edge?.attributes.metricID,
          this.pipelineType,
        );
        acc[edge.sourceHandle ?? ""] = metric?.value ?? 0;
        return acc;
      }, {});
  }
}
