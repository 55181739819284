import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import { isEqual } from "lodash";
import { memo, useState } from "react";
import { ParameterDefinition } from "../../../graphql/generated";
import { PlusCircleIcon, TrashIcon } from "../../Icons";
import { useValidationContext } from "../ValidationContext";
import { OTTLFieldInput } from "./OTTLFieldInput";
import { ParamInputProps } from "./ParameterInput";

export type FieldsValue = Fields[];

export interface Fields {
  fieldType: "body" | "attributes" | "resource";
  fieldAction: "insert" | "update" | "upsert";
  key: string;
  value: string;
}

const EMPTY_ITEM: Fields = {
  fieldType: "attributes",
  fieldAction: "upsert",
  key: "",
  value: "",
};

const EMPTY_VALUE: FieldsValue = [EMPTY_ITEM];

export const FieldsInput: React.FC<ParamInputProps<FieldsValue>> = ({
  definition,
  value: paramValue,
  readOnly,
  onValueChange,
}) => {
  const initValue =
    paramValue != null && paramValue.length > 0 ? paramValue : EMPTY_VALUE;
  const [controlValue, setControlValue] = useState<FieldsValue>(initValue);
  const { errors } = useValidationContext();

  function handleValueChange(newValue: FieldsValue) {
    if (isEqual(newValue, EMPTY_VALUE)) {
      onValueChange && onValueChange([]);
    } else {
      onValueChange && onValueChange(newValue);
    }
  }
  function handleAddField() {
    const newValue = [...controlValue, EMPTY_ITEM];
    setControlValue(newValue);
    handleValueChange(newValue);
  }

  function handleRemoveField(index: number) {
    const newValue = controlValue.filter((_, i) => i !== index);
    if (newValue.length === 0) {
      newValue.push(EMPTY_ITEM);
    }
    setControlValue(newValue);
    handleValueChange(newValue);
  }

  function handleItemChange(index: number, item: Fields) {
    const newValue = controlValue.map((v, i) => (i === index ? item : v));
    setControlValue(newValue);
    handleValueChange(newValue);
  }

  return (
    <>
      <Typography>{definition.label}</Typography>
      <Typography fontSize="0.75rem">{definition.description}</Typography>
      {errors[definition.name] && (
        <FormHelperText error>*Field and Value are required</FormHelperText>
      )}
      <Stack direction={"row"}>
        <Stack direction={"column"} flex={1}>
          <Box marginTop="8px">
            {controlValue.map((item, index) => (
              <Stack key={`fields-item-container-${index}`}>
                <Box marginTop="16px" />
                {definition.options.processorContext === "rename_fields_v2" ? (
                  <RenameFieldsItem
                    definition={definition}
                    item={item}
                    index={index}
                    onRemoveField={handleRemoveField}
                    onItemChange={handleItemChange}
                    handleAddField={handleAddField}
                    controlValue={controlValue}
                    errored={errors[definition.name] != null}
                    readOnly={readOnly || false}
                  />
                ) : (
                  <FieldItem
                    definition={definition}
                    item={item}
                    index={index}
                    onRemoveField={handleRemoveField}
                    onItemChange={handleItemChange}
                    handleAddField={handleAddField}
                    controlValue={controlValue}
                    errored={errors[definition.name] != null}
                    readOnly={readOnly || false}
                  />
                )}
              </Stack>
            ))}
          </Box>
          {!readOnly && (
            <Box marginLeft={1} marginTop={1}>
              <Button startIcon={<PlusCircleIcon />} onClick={handleAddField}>
                New Row
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
};

type FieldItemProps = {
  definition: ParameterDefinition;
  item: Fields;
  index: number;
  onRemoveField: (index: number) => void;
  onItemChange(index: number, item: Fields): void;
  handleAddField: () => void;
  controlValue: FieldsValue;
  errored: boolean;
  readOnly: boolean;
};

const FieldItem: React.FC<FieldItemProps> = memo(
  ({
    definition,
    item,
    index,
    onRemoveField,
    onItemChange,
    handleAddField,
    controlValue,
    errored,
    readOnly,
  }) => {
    // Special handling for enter key on Value fields
    function handleValueFieldEnter(
      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
      rowIndex: number,
    ) {
      if (e.key !== "Enter") {
        return;
      }

      e.preventDefault();

      // try to find the next input
      const nextInput = document.querySelector(
        `#${definition.name}-input-${rowIndex * 2 + 2}`,
      );

      if (nextInput != null) {
        (nextInput as HTMLElement).focus();
      } else {
        handleAddField();
      }
    }
    return (
      <Stack direction="row" spacing={0.5} flexGrow={1}>
        <Box minWidth="120px">
          <FormControl fullWidth disabled={readOnly}>
            <InputLabel id={`field-type-label-${index}`}>Field Type</InputLabel>
            <Select
              labelId={`field-type-label-${index}`}
              label="Field Type"
              size="small"
              autoFocus={index !== 0 && index === controlValue.length - 1}
              value={item.fieldType}
              onChange={(e) =>
                onItemChange(index, {
                  ...item,
                  fieldType: e.target.value as Fields["fieldType"],
                })
              }
            >
              <MenuItem value="body">Body</MenuItem>
              <MenuItem value="attributes">Attributes</MenuItem>
              <MenuItem value="resource">Resource</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box minWidth="100px">
          <FormControl fullWidth disabled={readOnly}>
            <InputLabel id={`field-action-label-${index}`}>Action</InputLabel>
            <Select
              labelId={`field-action-label-${index}`}
              label="Action"
              size="small"
              value={item.fieldAction}
              onChange={(e) =>
                onItemChange(index, {
                  ...item,
                  fieldAction: e.target.value as Fields["fieldAction"],
                })
              }
            >
              <MenuItem value="insert">Insert</MenuItem>
              <MenuItem value="update">Update</MenuItem>
              <MenuItem value="upsert">Upsert</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box flexGrow={1} minWidth={"130px"}>
          <FormControl
            variant="outlined"
            disabled={readOnly}
            sx={{ width: "100%" }}
            id={`${definition.name}-input-${index * 2}`}
            key={`${definition.name}-${index}-0-input`}
            data-testid={`${definition.name}-${index}-0-input`}
          >
            <InputLabel htmlFor="outlined-input"></InputLabel>
            <OTTLFieldInput
              definition={
                (definition = {
                  ...definition,
                  description: "",
                  name: "field",
                  label: "Field",
                  options: {
                    ottlContext: item.fieldType,
                  },
                })
              }
              errored={errored && item.key === ""}
              readOnly={readOnly}
              value={item.key}
              onValueChange={(value: string) =>
                onItemChange(index, {
                  ...item,
                  key: value,
                })
              }
            />
          </FormControl>
        </Box>
        <Box flexGrow={1} minWidth={"130px"}>
          <FormControl
            variant="outlined"
            disabled={readOnly}
            sx={{ width: "100%" }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              handleValueFieldEnter(
                e as React.KeyboardEvent<
                  HTMLInputElement | HTMLTextAreaElement
                >,
                index,
              );
            }}
          >
            <InputLabel htmlFor="outlined-input"></InputLabel>
            <OTTLFieldInput
              key={item.key}
              definition={{
                ...definition,
                description: "",
                name: "value",
                label: "Value",
                options: {
                  ottlContext: "",
                },
              }}
              errored={errored && item.value === ""}
              readOnly={readOnly}
              value={item.value}
              onValueChange={(value: string) =>
                onItemChange(index, {
                  ...item,
                  value: value,
                })
              }
            />
          </FormControl>
        </Box>
        <Stack width="28px" justifyContent="center">
          {!readOnly && (
            <IconButton
              size="small"
              onClick={() => onRemoveField(index)}
              sx={{
                alignItems: "center",
                width: "28px",
                height: "28px",
              }}
            >
              <TrashIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  },
);

const RenameFieldsItem: React.FC<FieldItemProps> = memo(
  ({
    definition,
    item,
    index,
    onRemoveField,
    onItemChange,
    handleAddField,
    controlValue,
    errored,
    readOnly,
  }) => {
    // Special handling for enter key on Value fields
    function handleValueFieldEnter(
      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
      rowIndex: number,
    ) {
      if (e.key !== "Enter") {
        return;
      }

      e.preventDefault();

      // try to find the next input
      const nextInput = document.querySelector(
        `#${definition.name}-input-${rowIndex * 2 + 2}`,
      );

      if (nextInput != null) {
        (nextInput as HTMLElement).focus();
      } else {
        handleAddField();
      }
    }
    return (
      <Stack direction="row" spacing={1} width={"100%"} flexGrow={1}>
        <Box minWidth="120px">
          <FormControl fullWidth disabled={readOnly}>
            <InputLabel id={`field-type-label-${index}`}>Field Type</InputLabel>
            <Select
              labelId={`field-type-label-${index}`}
              label="Field Type"
              size="small"
              autoFocus={index !== 0 && index === controlValue.length - 1}
              value={item.fieldType}
              onChange={(e) =>
                onItemChange(index, {
                  ...item,
                  fieldType: e.target.value as Fields["fieldType"],
                })
              }
            >
              <MenuItem value="body">Body</MenuItem>
              <MenuItem value="attributes">Attributes</MenuItem>
              <MenuItem value="resource">Resource</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box flexGrow={1} minWidth={"140px"}>
          <FormControl
            variant="outlined"
            disabled={readOnly}
            sx={{ width: "100%" }}
            id={`${definition.name}-input-${index * 2}`}
            key={`${definition.name}-${index}-0-input`}
            data-testid={`${definition.name}-${index}-0-input`}
          >
            <InputLabel htmlFor="outlined-input"></InputLabel>
            <OTTLFieldInput
              definition={
                (definition = {
                  ...definition,
                  description: "",
                  name: "field",
                  label: "Old Name",
                  options: {
                    ottlContext: item.fieldType,
                  },
                })
              }
              errored={errored && item.key === ""}
              readOnly={readOnly}
              value={item.key}
              onValueChange={(value: string) =>
                onItemChange(index, {
                  ...item,
                  key: value,
                })
              }
            />
          </FormControl>
        </Box>
        <Box flexGrow={1} minWidth={"140px"}>
          <FormControl
            variant="outlined"
            disabled={readOnly}
            sx={{ width: "100%" }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              handleValueFieldEnter(
                e as React.KeyboardEvent<
                  HTMLInputElement | HTMLTextAreaElement
                >,
                index,
              );
            }}
          >
            <InputLabel htmlFor="outlined-input"></InputLabel>
            <OTTLFieldInput
              key={item.key}
              definition={
                (definition = {
                  ...definition,
                  description: "",
                  name: "valueField",
                  label: "New Name",
                  options: {
                    ottlContext: "",
                  },
                })
              }
              errored={errored && item.value === ""}
              readOnly={readOnly}
              value={item.value}
              onValueChange={(value: string) =>
                onItemChange(index, {
                  ...item,
                  value: value,
                })
              }
            />
          </FormControl>
        </Box>
        <Stack width="28px" justifyContent="center">
          {!readOnly && (
            <IconButton
              size="small"
              onClick={() => onRemoveField(index)}
              sx={{
                alignItems: "center",
                width: "28px",
                height: "28px",
              }}
            >
              <TrashIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  },
);
