import { MenuItem, Menu } from "@mui/material";
import { useRef, useState, useCallback } from "react";
import { ChevronLeft } from "../Icons";
import styles from "./sub-menu-item.module.scss";

type MoreMenuItemProps = {
  label: string;
};

export const SubMenuItem: React.FC<MoreMenuItemProps> = ({
  label,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const menuItemRef = useRef<HTMLLIElement>(null);

  return (
    <MenuItem
      ref={menuItemRef}
      onMouseEnter={open}
      onMouseLeave={close}
      data-testid={`submenu-item-${label}`}
      disableRipple
    >
      <ChevronLeft
        className={styles["settings-icon"]}
        data-testid={`submenu-chevron-${label}`}
      />
      <span data-testid={`submenu-label-${label}`}>{label}</span>
      <Menu
        TransitionProps={{ onExited: () => menuItemRef.current?.focus() }}
        disableRestoreFocus
        data-testid={`submenu-list-${label}`}
        sx={{
          pointerEvents: "none",
          "& .MuiList-root": {
            pointerEvents: "auto",
          },
        }}
        slotProps={{
          paper: {
            className: styles["settings-paper"],
          },
        }}
        MenuListProps={{
          sx: { py: 0 },
        }}
        anchorEl={menuItemRef.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </Menu>
    </MenuItem>
  );
};

export default SubMenuItem;
