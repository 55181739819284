import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Handle, Position } from "reactflow";
import { classes } from "../../utils/styles";
import styles from "./cards.module.scss";

interface ConnectorCardProps {
  onClick?: () => void;
  label: string;
}

export const ConnectorCard: React.FC<ConnectorCardProps> = ({
  onClick,
  label,
}) => {
  return (
    <Box>
      <Card className={styles["resource-card"]}>
        <CardActionArea
          onClick={onClick}
          className={classes([
            styles.action,
            onClick == null ? styles.cursorDefault : undefined,
          ])}
          disabled={onClick == null}
        >
          <Handle type="source" position={Position.Right} />
          <Handle type="target" position={Position.Left} />
          <CardContent>
            <Stack
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Typography fontSize={12} textOverflow={"ellipsis"}>
                {label}
              </Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};
