import { Box, Card, CardActionArea, Stack } from "@mui/material";
import { Handle, Position } from "reactflow";
import { classes } from "../../utils/styles";
import { CountChip } from "../CountChip";
import { ProcessorIcon } from "../Icons";
import styles from "./cards.module.scss";

interface ProcessorCardProps {
  onClick?: () => void;
  processorCount: number;
  recommendation?: boolean;
}

export const ProcessorCard: React.FC<ProcessorCardProps> = ({
  onClick,
  processorCount,
  recommendation,
}) => {
  return (
    <Box className={recommendation ? styles["processor-recommendation"] : ""}>
      <Card className={styles["processor-card"]} onClick={onClick}>
        <CardActionArea
          className={classes([
            styles.processorAction,
            onClick == null ? styles.cursorDefault : undefined,
          ])}
          disabled={onClick == null}
        >
          <Handle type="source" position={Position.Right} />
          <Handle type="target" position={Position.Left} />
          <Stack
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <ProcessorIcon width={38} />
          </Stack>
        </CardActionArea>
      </Card>
      {processorCount > 0 && (
        <CountChip className={styles["count-chip"]} count={processorCount} />
      )}
    </Box>
  );
};
