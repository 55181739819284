import { enqueueSnackbar } from "notistack";
import { Kind } from "../../../graphql/generated";
import { BPConfiguration } from "../../../utils/classes";
import { wrapResource } from "../../../utils/classes/resource";
import { RoutingConnectorCard } from "../../Cards/RoutingConnectorCard";
import { useComponentDialog } from "../../ComponentDialog/ComponentDialogContext";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";

export function RoutingConnectorNodeV2({
  data,
}: {
  data: {
    id: string;
    metric: string;
    attributes: Record<string, any>;
  };
}) {
  const { id, attributes } = data;
  const { configuration } = useV2PipelineGraph();
  const { editComponent } = useComponentDialog();

  const { componentPath } = attributes;
  const c = new BPConfiguration(configuration!);
  const resource = c.findResource(componentPath);

  const nodeResource = wrapResource(attributes["resource"]);

  function editConnector(componentPath: string) {
    const config = new BPConfiguration(configuration);
    const component = config.findResource(componentPath);
    if (component == null) {
      enqueueSnackbar(`Component ${componentPath} not found in Configuration`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    editComponent(Kind.Connector, component);
  }

  return (
    <>
      <RoutingConnectorCard
        nodeId={id}
        onClick={() =>
          resource && editConnector(resource.componentPath("connectors"))
        }
        resource={resource}
        nodeResource={nodeResource}
      />
    </>
  );
}
