import { edition } from "../components/BindplaneVersion/utils";

/**
 *
 * @param licenseType an optional license type, if not present
 * it will be inferred from the `edition` util.
 * @returns branding information based on the license type
 */
export function useBranding(licenseType?: string): {
  shortName: string;
  longName: string;
  supportEmail: string;
  eulaUrl: string;
  slackUrl?: string;
} {
  const license = licenseType ?? edition();
  switch (license) {
    case "Honeycomb":
      return {
        shortName: "HTP",
        longName: "Honeycomb Telemetry Pipeline",
        supportEmail: "mailto:support@honeycomb.io",
        eulaUrl: "https://www.honeycomb.io/acceptable-use-policy",
      };
    default:
      return {
        shortName: "BindPlane",
        longName: "BindPlane OP",
        supportEmail: "mailto:support.bindplaneop@observiq.com",
        eulaUrl: "https://observiq.com/legal/eula",
        slackUrl: "https://observiq.com/support-bindplaneop/",
      };
  }
}
