import { usePipelineGraph } from "../../../hooks/usePipelineGraph";
import { dismissedRecommendationStorage } from "../../../utils/dismissed-recommendations/dismissed-recommendations";
import { CardMeasurementContent } from "../../CardMeasurementContent/CardMeasurementContent";
import { ProcessorCard } from "../../Cards/ProcessorCard";
import { MinimumRequiredConfig } from "../PipelineGraph";
import { AttributeName } from "./nodeUtils";

export function ProcessorNode({
  data,
}: {
  data: {
    // ID will have the form source/source0/processors, source/source1/processors, dest-name/destination/processors, etc
    id: string;
    metric: string;
    attributes: Record<string, any>;
    configuration: MinimumRequiredConfig;
  };
}) {
  const { id, metric, configuration, attributes } = data;
  const { editProcessors } = usePipelineGraph();

  const isSource = isSourceID(id);

  let processorCount = 0;
  let resourceIndex = -1;
  let name = "";
  if (isSource) {
    name = getSourceName(id);
    if (typeof attributes["sourceIndex"] === "number") {
      resourceIndex = attributes["sourceIndex"];
    }
    processorCount =
      configuration?.spec?.sources![resourceIndex]?.processors?.length ?? 0;
  } else {
    name = getDestinationName(id);
    if (typeof attributes["destinationIndex"] === "number") {
      resourceIndex = attributes["destinationIndex"];
    }

    const destination = configuration?.spec?.destinations![resourceIndex];
    processorCount = destination?.processors?.length ?? 0;
  }

  let recommendation = false;
  if (typeof attributes["recommendations"] === "object") {
    // we have recommendations, check each one to see if it's dismissed
    recommendation = true;
    const dismissed = dismissedRecommendationStorage.getDismissed(name);
    const ids = attributes["recommendations"];
    for (const key in ids) {
      if (dismissed.includes(ids[key])) {
        recommendation = false;
      } else {
        // at least one recommendation is not dismissed
        recommendation = true;
        break;
      }
    }
  }
  return (
    <>
      <ProcessorCard
        onClick={() => editProcessors(attributes[AttributeName.ComponentPath])}
        processorCount={processorCount}
        recommendation={recommendation}
      />
      <CardMeasurementContent>{metric}</CardMeasurementContent>
    </>
  );
}

/**
 * Is the ID a source ID?
 * @param id the node ID
 */
export function isSourceID(id: string): boolean {
  return id.startsWith("source/");
}

export function getDestinationName(id: string): string {
  // /destination/name/processors
  const REGEX = /^destination\/(?<name>.*)\/processors$/;
  const match = id.match(REGEX);
  return match?.groups?.["name"] ?? "";
}

export function getSourceName(id: string): string {
  // /destination/name/processors
  const REGEX = /^source\/(?<name>.*)\/processors$/;
  const match = id.match(REGEX);
  return match?.groups?.["name"] ?? "";
}
