import { Kind } from "../../graphql/generated";

export type ComponentType =
  | "sources"
  | "destinations"
  | "processors"
  | "connectors";

export function typeFrom(componentPath: string): ComponentType {
  return componentPath.split("/")[0] as ComponentType;
}

export function IDFrom(componentPath: string): string {
  return componentPath.split("/")[1];
}

export function kindFrom(componentPath: string): Kind {
  switch (typeFrom(componentPath)) {
    case "sources":
      return Kind.Source;
    case "destinations":
      return Kind.Destination;
    case "processors":
      return Kind.Processor;
    case "connectors":
      return Kind.Connector;

    default:
      return Kind.Unknown;
  }
}

/**
 * Returns the ResourceType of the given componentPath, e.g. sources/123 -> SourceType
 */
export function resourceTypeKindFrom(componentPath: string): Kind {
  return resourceTypeOfKind(kindFrom(componentPath));
}

/**
 * Returns the ResourceType of the given Kind, e.g. Source -> SourceType
 */
export function resourceTypeOfKind(kind: Kind): Kind {
  return `${kind}Type` as Kind;
}

/**
 * Returns the ComponentType of the given Kind, e.g. Source -> sources
 */
export function componentTypeOfKind(kind: Kind): ComponentType {
  switch (kind) {
    case Kind.Source:
      return "sources";
    case Kind.Destination:
      return "destinations";
    case Kind.Processor:
      return "processors";
    case Kind.Connector:
      return "connectors";
    default:
      return "sources";
  }
}
