import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useBranding } from "../../hooks/useBranding";

interface LimitConnectedAgentsProps {
  onClose: () => void;
  open: boolean;
  agentLimit: number;
  licenseType: string;
}

export const LimitConnectedAgentsDialog: React.FC<
  LimitConnectedAgentsProps
> = ({ onClose, open, agentLimit, licenseType }) => {
  const { supportEmail } = useBranding();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>Agent Limit Reached</DialogTitle>
      <DialogContent>
        <Typography>
          You've reached the <strong>{agentLimit}-agent limit</strong> of your{" "}
          <strong>{licenseType}</strong> license. If you'd like to manage more
          agents, please reach out to our team and we can upgrade your license.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" href={supportEmail}>
          Contact Us
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
