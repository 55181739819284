import { ApolloError } from "@apollo/client";
import { Card, Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ReactFlowProvider } from "reactflow";
import {
  useGetConfigurationWithRoutesQuery,
  usePreviewFeatureGateQuery,
} from "../../graphql/generated";
import { AddDestinationsSection } from "../../pages/configurations/configuration/AddDestinationsSection";
import { AddSourcesSection } from "../../pages/configurations/configuration/AddSourcesSection";
import { Page } from "../../utils/graph/utils";
import { trimVersion } from "../../utils/version-helpers";
import { ComponentDialogProvider } from "../ComponentDialog/ComponentDialogContext";
import { ConfigurationFlowV2 } from "../ConfigurationFlowV2";
import { DEFAULT_TELEMETRY_TYPE } from "../MeasurementControlBar";
import { ProcessorDialogWithPreview } from "../ProcessorDialogWithPreview/ProcessorDialogWithPreview";
import { ProcessorDialog } from "../ProcessorsDialog/ProcessorDialog";
import { PipelineGraphErrorBoundary } from "./PipelineGraphErrorBoundary";
import { V2PipelineGraphProvider } from "./PipelineGraphV2Context";
import { RoutingContextProvider } from "./RoutingContext/RoutingContext";
import styles from "./pipeline-graph-v2.module.scss";

interface PipelineGraphV2Props {
  // configurationName is the versioned name of the configuration
  configurationName: string;
  selectedTelemetry: string;
  period: string;
  // if present the telemetry will be shown for the agent
  agentID?: string;

  readOnly?: boolean;
}

export const PipelineGraphV2: React.FC<PipelineGraphV2Props> = ({
  readOnly,
  configurationName,
  period,
  selectedTelemetry,
  agentID,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [addSourceOpen, setAddSourceOpen] = useState(false);
  const [addDestinationOpen, setAddDestinationOpen] = useState(false);

  function onError(err: ApolloError) {
    console.error(err);
    enqueueSnackbar(err.message, { variant: "error" });
  }

  const { data, refetch: refetchConfiguration } =
    useGetConfigurationWithRoutesQuery({
      variables: {
        name: configurationName,
      },
      fetchPolicy: "network-only",
      onError,
    });

  const [previewEnabled, setPreviewEnabled] = useState(false);
  usePreviewFeatureGateQuery({
    onCompleted(data) {
      setPreviewEnabled(data.featureGate);
    },
    onError(err) {
      console.error(err);
      enqueueSnackbar("Error getting feature gate info", {
        variant: "error",
        key: "get-feature-gate-error",
      });
    },
  });

  if (data?.configuration == null) {
    return null;
  }

  return (
    <PipelineGraphErrorBoundary>
      <ReactFlowProvider>
        <V2PipelineGraphProvider
          period={period}
          selectedTelemetryType={selectedTelemetry || DEFAULT_TELEMETRY_TYPE}
          configuration={data.configuration}
          refetchConfiguration={refetchConfiguration}
          addSourceOpen={addSourceOpen}
          setAddSourceOpen={setAddSourceOpen}
          addDestinationOpen={addDestinationOpen}
          setAddDestinationOpen={setAddDestinationOpen}
          readOnly={readOnly}
          agentID={agentID}
        >
          <ComponentDialogProvider
            configuration={data?.configuration}
            onDialogClose={refetchConfiguration}
            readOnly={readOnly}
          >
            <RoutingContextProvider
              configuration={data.configuration}
              readOnly={!!readOnly}
            >
              <GraphContainer>
                <Card className={styles.card}>
                  <ConfigurationFlowV2
                    period={period}
                    selectedTelemetry={selectedTelemetry}
                    page={Page.Configuration}
                    loading={data?.configuration == null}
                    configurationName={trimVersion(configurationName)}
                  />
                </Card>
              </GraphContainer>
              {previewEnabled ? (
                <ProcessorDialogWithPreview />
              ) : (
                <ProcessorDialog />
              )}
              {!readOnly && data?.configuration && (
                <>
                  <AddSourcesSection
                    configuration={data.configuration}
                    refetchConfiguration={refetchConfiguration}
                    setAddDialogOpen={setAddSourceOpen}
                    addDialogOpen={addSourceOpen}
                  />
                  <AddDestinationsSection
                    configuration={data.configuration}
                    refetchConfiguration={refetchConfiguration}
                    setAddDialogOpen={setAddDestinationOpen}
                    addDialogOpen={addDestinationOpen}
                  />
                </>
              )}
            </RoutingContextProvider>
          </ComponentDialogProvider>
        </V2PipelineGraphProvider>
      </ReactFlowProvider>
    </PipelineGraphErrorBoundary>
  );
};

const GraphContainer: React.FC = ({ children }) => {
  return (
    <Paper classes={{ root: styles.container }} elevation={1}>
      {children}
    </Paper>
  );
};
