import { NodeProps } from "reactflow";
import { IDFrom, typeFrom } from "../../../utils/classes/component-type";
import { findResource } from "../../../utils/classes/configuration";
import { dismissedRecommendationStorage } from "../../../utils/dismissed-recommendations/dismissed-recommendations";
import { ProcessorCardV2 } from "../../Cards/ProcessorCardV2";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { useRouting } from "../RoutingContext/RoutingContext";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName, V2NodeData } from "../types";

/**
 * ProcessorNodeV2 is the node rendered for the processors directly connected to
 * a source or a destination.
 */
export const ProcessorNodeV2: React.FC<NodeProps<V2NodeData>> = ({
  data,
  id,
}) => {
  const { attributes } = data;
  const { configuration, editProcessors, setHoveredSet } = useV2PipelineGraph();
  const { connectableComponentPaths } = useRouting();

  if (!configuration || !attributes[AttributeName.ComponentPath]) {
    return null;
  }

  const componentPath = attributes[AttributeName.ComponentPath];
  const resource = findResource(configuration, componentPath);
  let name = IDFrom(componentPath);

  let recommendation = false;
  if (typeof attributes["recommendations"] === "object") {
    // we have recommendations, check each one to see if it's dismissed
    recommendation = true;
    const dismissed = dismissedRecommendationStorage.getDismissed(name);
    const recIDs = attributes[AttributeName.Recommendations];
    for (const recID in recIDs) {
      if (dismissed.includes(recID)) {
        recommendation = false;
      } else {
        // at least one recommendation is not dismissed
        recommendation = true;
        break;
      }
    }
  }

  return (
    <RoutingNodeWrapper
      componentType={typeFrom(attributes[AttributeName.ComponentPath])}
      componentPath={attributes[AttributeName.ComponentPath]}
    >
      <div
        onMouseEnter={() => {
          setHoveredSet(data.connectedNodesAndEdges);
        }}
        onMouseLeave={() => setHoveredSet([])}
      >
        <ProcessorCardV2
          nodeId={id}
          onClick={
            connectableComponentPaths.includes(
              attributes[AttributeName.ComponentPath],
            )
              ? () => {}
              : () => editProcessors(attributes[AttributeName.ComponentPath])
          }
          processorCount={resource?.processors?.length ?? 0}
          recommendation={recommendation}
        />
      </div>
    </RoutingNodeWrapper>
  );
};
