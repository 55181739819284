import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Handle, Position } from "reactflow";
import { Route } from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { BPParameterizedResource } from "../../utils/classes/resource";
import { NodeId } from "../ConfigurationFlowV2/graph";
import { useV2PipelineGraph } from "../PipelineGraphV2/PipelineGraphV2Context";
import styles from "./cards.module.scss";

interface RoutingConnectorRoute {
  routeID: string;
}

class RoutingConnectorRoutes {
  routes: RoutingConnectorRoute[] = [];
  rates: number[] = [];
  remainder = 100;
  hasRoute(route: RoutingConnectorRoute) {
    return this.routes.some((exist) => exist.routeID === route.routeID);
  }
  addRoutes(...routes: RoutingConnectorRoute[]) {
    for (const route of routes) {
      if (!this.hasRoute(route)) {
        this.routes.push(route);

        this.rates.push(Math.random() * this.remainder);
        this.remainder -= this.rates[this.rates.length - 1];
      }
    }
  }
}

interface RoutingConnectorCardProps {
  nodeId: NodeId;
  onClick?: () => void;
  resource: BPResourceConfiguration | null;
  // if resource references the a library resource, this is the actual resource.
  nodeResource: BPParameterizedResource;
}

export const RoutingConnectorCard: React.FC<RoutingConnectorCardProps> = ({
  nodeId,
  onClick,
  nodeResource,
}) => {
  const { metrics } = useV2PipelineGraph();

  const routesParameter = nodeResource.getParameterValue<Route[]>("routes", []);

  // compute handles dynamically based on the routes
  const routes = new RoutingConnectorRoutes();
  routesParameter?.forEach((route) => {
    if (route.id != null) {
      routes.addRoutes({ routeID: route.id });
    }
  });

  const routeRowHeight = 40;

  return (
    <Box>
      <Card className={styles["router-connector-card"]} onClick={onClick}>
        <CardActionArea disabled={onClick == null}>
          <CardContent style={{ padding: 0 }}>
            <>
              {routes.routes.map((route, index) => (
                <Handle
                  type="source"
                  position={Position.Right}
                  id={route.routeID}
                  style={{
                    top: (index + 1) * routeRowHeight + routeRowHeight / 2,
                  }}
                />
              ))}
            </>
            <Handle
              type="target"
              position={Position.Left}
              style={{ top: routeRowHeight / 2 }}
            />
            <Stack
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <Stack
                className={styles["router-connector-title"]}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  position: "relative",
                }}
              >
                <Stack
                  className={styles["bar-graph-background"]}
                  style={
                    {
                      "--percentage":
                        (metrics?.getInboundMetric(nodeId) ?? 0) > 0
                          ? `100%`
                          : `0%`,
                    } as React.CSSProperties
                  }
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction={"column"}
                >
                  <Typography className={styles["router-connector-throughput"]}>
                    {metrics?.getInboundMetricFormatted(nodeId)}
                  </Typography>
                </Stack>
                <Typography>Router</Typography>
              </Stack>
              {routes.routes.map((route, index) => (
                <Stack
                  className={styles["router-connector-route"]}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack
                    className={styles["bar-graph-background"]}
                    style={
                      {
                        "--percentage": `${metrics?.getOutboundMetricPercentage(nodeId, route.routeID) ?? 0}%`,
                      } as React.CSSProperties
                    }
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={"column"}
                  >
                    <Typography
                      className={styles["router-connector-throughput"]}
                    >
                      {metrics?.getOutboundMetricFormatted(
                        nodeId,
                        route.routeID,
                      )}
                    </Typography>
                  </Stack>
                  <Typography>{route.routeID}</Typography>
                </Stack>
              ))}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};
