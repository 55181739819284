import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Kind } from "../../../graphql/generated";

interface UnlinkFromLibraryDialogProps extends DialogProps {
  onUnlink: (values: { [key: string]: any }, name: string) => void;
  onClose: () => void;
  open: boolean;
  kind:
    | Kind.Processor
    | Kind.Extension
    | Kind.Source
    | Kind.Destination
    | Kind.Connector;
  name: string;
}

export const UnlinkFromLibraryDialog: React.FC<
  UnlinkFromLibraryDialogProps
> = ({ onUnlink, onClose, open, kind, name }) => {
  return (
    <Dialog data-testid="unlink-from-library-dialog" open={open}>
      <DialogTitle>
        Unlink <Box sx={{ fontWeight: "bold", display: "inline" }}>{name}</Box>{" "}
        from Library?
      </DialogTitle>
      <DialogContent>
        <Typography>
          This will unlink the {kind} - {name} from the Library. It will still
          exist in the configuration, but future changes to it will not be
          reflected in the Library.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          data-testid={"unlink-from-library-dialog-cancel-button"}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="warning"
          onClick={() => onUnlink({}, name)}
          data-testid={"unlink-from-library-dialog-unlink-button"}
        >
          Unlink
        </Button>
      </DialogActions>
    </Dialog>
  );
};
