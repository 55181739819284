import {
  Fab,
  Link,
  Stack,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import React, { useState, MouseEvent, useEffect } from "react";
import { useBranding } from "../../hooks/useBranding";
import { getSaasEnabled } from "../../utils/get-saas-enabled";
import {
  EmailIcon,
  HelpCircleIcon,
  MessageSquareIcon,
  QuestionMarkIcon,
  SlackIcon,
  ToolIcon,
} from "../Icons";
import styles from "./help-menu.module.scss";

export const HelpMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chatWidgetVisible, setChatWidgetVisible] = useState<boolean>(false);

  // add hubspot chat script to DOM
  useEffect(() => {
    (window as any).hsConversationsSettings = {
      loadImmediately: false,
    };
    const script = document.createElement("script");
    script.src = "//js-na1.hs-scripts.com/23826596.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  });

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickChatButton = () => {
    (window as any).HubSpotConversations.widget.load();
    (window as any).HubSpotConversations.widget.open();
    (window as any).HubSpotConversations.on("widgetLoaded", () => {
      handleClose();
      setChatWidgetVisible(true);
    });
    (window as any).HubSpotConversations.on("widgetClosed", () => {
      (window as any).HubSpotConversations.widget.remove();
      setChatWidgetVisible(false);
    });
  };

  const branding = useBranding();
  const { supportEmail } = branding;
  const isSaaS = getSaasEnabled();

  if (chatWidgetVisible) {
    return null;
  }

  return (
    <>
      <Fab
        size="small"
        color="primary"
        aria-label="help"
        onClick={handleOpen}
        className={styles["help-menu-fab"]}
        data-testid="help-menu-fab"
      >
        <QuestionMarkIcon></QuestionMarkIcon>
      </Fab>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        className={styles["help-menu"]}
        data-testid="help-menu"
      >
        {branding.slackUrl && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            data-testid="slack-link"
            href={branding.slackUrl}
            underline="none"
            color="inherit"
            variant="inherit"
            target="_blank"
            rel="noopener"
          >
            <Stack direction={"row"}>
              <SlackIcon className={styles["help-menu-icon"]} />
              <Typography>Slack Community</Typography>
            </Stack>
          </MenuItem>
        )}
        <MenuItem
          onClick={handleClose}
          component={Link}
          data-testid="support-link"
          href={supportEmail}
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <EmailIcon className={styles["help-menu-icon"]} />
            <Typography>Email Support</Typography>
          </Stack>
        </MenuItem>
        {isSaaS && (
          <MenuItem
            className={styles["help-menu-button"]}
            onClick={handleClickChatButton}
            component={Button}
            data-testid="chat-link"
            color="inherit"
          >
            <Stack direction={"row"}>
              <MessageSquareIcon className={styles["help-menu-icon"]} />
              <Typography>Chat</Typography>
            </Stack>
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={handleClose}
          component={Link}
          data-testid="doc-link"
          href="https://observiq.com/docs/getting-started/quickstart-guide"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <HelpCircleIcon className={styles["help-menu-icon"]} />
            <Typography>Documentation</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          data-testid="api-documentation-link"
          href="/swagger"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <ToolIcon className={styles["help-menu-icon"]} />
            <Typography>API Documentation</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};

export default HelpMenu;
