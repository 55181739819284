import { Handle, Position } from "reactflow";
import { ProcessorCard } from "../../Cards/ProcessorCard";

export function DummyProcessorNode() {
  return (
    <>
      <Handle type="target" position={Position.Left} />
      <ProcessorCard processorCount={0} />
      <Handle type="source" position={Position.Right} />
    </>
  );
}
