import { useV1PipelineGraph } from "../components/PipelineGraph/PipelineGraphContext";
import { useV2PipelineGraph } from "../components/PipelineGraphV2/PipelineGraphV2Context";

/**
 * usePipelineGraph will return the context value for either the V1 or V2
 * pipeline graphs.
 */
export function usePipelineGraph() {
  const v1 = useV1PipelineGraph();
  const v2 = useV2PipelineGraph();

  if (v2.configuration) {
    return v2;
  }

  return v1;
}
