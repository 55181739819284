import { LicenseInfo } from "@mui/x-license-pro";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import "./styles/globals.scss";

/*
Here we are setting the MUI Premium license key.  We expect it
to be bundled with the served JS, but thats alright.  From MUI:

   The license key is checked without making any network
   requests—it's designed to be public. It's expected that
   the license key will be exposed in a JavaScript bundle;
   we simply ask licensed users not to actively publicize
   their license key.

This works around having to set the license key at build time
and for development.
*/
LicenseInfo.setLicenseKey(
  "9e2e66d2d6e08fd9b74a404114a22a7dTz0xMDA4MTEsRT0xNzYxNzQ3OTU1MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=",
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
