import { Divider, Grid2 as Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import {
  BoolParamInput,
  DateTimeInput,
  EnumListInput,
  EnumParamInput,
  EnumsParamInput,
  FileLogSortInput,
  IntParamInput,
  MapParamInput,
  MetricsParamInput,
  OrderedListInput,
  OTTLFieldInput,
  StringParamInput,
  StringsParamInput,
  TimezoneParamInput,
  YamlParamInput,
} from ".";
import { ParameterDefinition, ParameterType } from "../../../graphql/generated";
import { useResourceFormValues } from "../ResourceFormContext";
import { AWSCloudwatchInput } from "./AWSCloudwatchFieldInput";
import { ConditionInput } from "./ConditionInput";
import { ExtractMetricsInput } from "./ExtractMetricsInput";
import { FieldsInput } from "./FieldsInput";
import { FractionParamInput } from "./FractionParamInput";
import { GoogleSecOpsStandardizationInput } from "./GoogleSecOpsStandardizationInput";
import { OTTLFieldsInput } from "./OTTLFieldsInput";
import { RecombineConditionsInput } from "./RecombineConditionsInput";
import { RolloutStagesInput } from "./RolloutStagesInput";
import { RoutesInput } from "./RoutesInput";
import { SectionHeaderParam } from "./SectionHeaderParam";
import { TelemetrySelectorInput } from "./TelemetrySelectorInput";

export interface ParamInputProps<T> {
  definition: ParameterDefinition;
  value?: T;
  onValueChange?: (v: T) => void;
  readOnly?: boolean;
}

export const ParameterInput: React.FC<{
  definition: ParameterDefinition;
  readOnly?: boolean;
}> = ({ definition, readOnly }) => {
  const { formValues, setFormValues } = useResourceFormValues();
  const onValueChange = useMemo(
    () => (newValue: any) => {
      setFormValues((prev) => ({ ...prev, [definition.name]: newValue }));
    },
    [definition.name, setFormValues],
  );

  const subHeader: JSX.Element | null = useMemo(() => {
    if (definition.options.subHeader) {
      return (
        <Grid size={{ xs: 12 }}>
          <Typography>{definition.options.subHeader}</Typography>
        </Grid>
      );
    }

    return null;
  }, [definition.options.subHeader]);

  const divider: JSX.Element | null = useMemo(() => {
    if (definition.options.horizontalDivider) {
      return (
        <Grid size={{ xs: 12 }}>
          <Divider />
        </Grid>
      );
    }
    return null;
  }, [definition.options.horizontalDivider]);

  const Control: JSX.Element = useMemo(() => {
    const dataTestId = `parameter-input-${definition.name.toLowerCase()}`;
    switch (definition.type) {
      case ParameterType.String:
        return (
          <StringParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Strings:
        return (
          <StringsParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Enum:
        return (
          <EnumParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Enums:
        return (
          <EnumsParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.EnumList:
        return (
          <EnumListInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.OrderedList:
        return (
          <OrderedListInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Bool:
        return (
          <BoolParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={definition.name}
          />
        );

      case ParameterType.Int:
        return (
          <IntParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Fraction:
        return (
          <FractionParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Fields:
        return (
          <FieldsInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Map:
        return (
          <MapParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Yaml:
        return (
          <YamlParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Timezone:
        return (
          <TimezoneParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.Metrics:
        return (
          <MetricsParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.AwsCloudwatchNamedField:
        return (
          <AWSCloudwatchInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.FileLogSort:
        return (
          <FileLogSortInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.MapToEnum:
        return (
          <MapParamInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.OttlField:
        return (
          <OTTLFieldInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.OttlFields:
        return (
          <OTTLFieldsInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.TelemetrySelector:
        return (
          <TelemetrySelectorInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.SectionHeader:
        return (
          <SectionHeaderParam
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.Condition:
        return (
          <ConditionInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.DateTime:
        return (
          <DateTimeInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.RolloutStages:
        return (
          <RolloutStagesInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.RecombineConditions:
        return (
          <RecombineConditionsInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.ExtractMetrics:
        return (
          <ExtractMetricsInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );

      case ParameterType.GoogleSecOpsStandardization:
        return (
          <GoogleSecOpsStandardizationInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
      case ParameterType.Routes:
        return (
          <RoutesInput
            definition={definition}
            value={formValues[definition.name]}
            onValueChange={onValueChange}
            readOnly={readOnly}
            data-testid={dataTestId}
          />
        );
    }
  }, [definition, formValues, onValueChange, readOnly]);

  const gridColumns = useMemo(() => {
    if (isTelemetryHeader(definition)) {
      return 12;
    }

    if (isSectionHeader(definition)) {
      return 12;
    }

    switch (definition.type) {
      case ParameterType.Metrics:
      case ParameterType.AwsCloudwatchNamedField:
      case ParameterType.FileLogSort:
      case ParameterType.Condition:
      case ParameterType.RecombineConditions:
      case ParameterType.Fields:
        return 12;
    }

    return definition.options.gridColumns ?? 6;
  }, [definition]);

  return (
    <>
      {subHeader}
      <Grid size={{ xs: gridColumns }}>{Control}</Grid>
      {divider}
    </>
  );
};

function isTelemetryHeader(definition: ParameterDefinition) {
  return ["enable_metrics", "enable_logs", "enable_traces"].includes(
    definition.name,
  );
}

function isSectionHeader(definition: ParameterDefinition) {
  return definition.options.sectionHeader === true;
}
