import { Handle, Position } from "reactflow";
import { ProcessorCardV2 } from "../../Cards/ProcessorCardV2";

export function DummyProcessorNodeV2() {
  return (
    <>
      <Handle type="target" position={Position.Left} />
      <ProcessorCardV2 processorCount={0} />
      <Handle type="source" position={Position.Right} />
    </>
  );
}
