import { createContext, useContext, useState } from "react";
import { Kind } from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import { ConnectorDialog } from "./ComponentDialog";

type ComponentKind =
  | Kind.Source
  | Kind.Destination
  | Kind.Processor
  | Kind.Connector;

export interface ComponentDialogContextValue {
  editingKind: ComponentKind | null;
  editingComponent: BPResourceConfiguration | null;
  editComponent: (
    kind: ComponentKind,
    resource: BPResourceConfiguration,
  ) => void;
  closeComponentDialog: () => void;
  editComponentOpen: boolean;
}

export interface ComponentDialogProviderProps {
  // configuration if editing a Component within a configuration, undefined in the library
  configuration?: MinimumRequiredConfig;
  onDialogClose?: () => void;
  readOnly?: boolean;
}

const defaultValue: ComponentDialogContextValue = {
  editingKind: null,
  editingComponent: null,
  editComponent: (kind: ComponentKind, resource: BPResourceConfiguration) => {},
  closeComponentDialog: () => {},
  editComponentOpen: false,
};

export const ComponentDialogContext = createContext(defaultValue);

export const ComponentDialogProvider: React.FC<
  ComponentDialogProviderProps
> = ({ readOnly, children, configuration, onDialogClose }) => {
  const [editing, setEditing] = useState<{
    kind: ComponentKind;
    resource: BPResourceConfiguration;
  } | null>(null);
  const [editComponentOpen, setEditComponentOpen] = useState(false);

  function editComponent(
    kind: ComponentKind,
    resource: BPResourceConfiguration,
  ) {
    setEditing({ kind, resource });
    setEditComponentOpen(true);
  }

  function closeComponentDialog() {
    setEditComponentOpen(false);
    // Reset the connecting component on a timeout to avoid a flash of empty state.
    setTimeout(() => {
      setEditing(null);
    }, 300);
  }

  return (
    <ComponentDialogContext.Provider
      value={{
        editingKind: editing?.kind ?? null,
        editingComponent: editing?.resource ?? null,
        editComponent,
        closeComponentDialog,
        editComponentOpen,
      }}
    >
      <ConnectorDialog
        configuration={configuration}
        onDialogClose={onDialogClose}
        readOnly={readOnly}
      />
      {children}
    </ComponentDialogContext.Provider>
  );
};

export function useComponentDialog(): ComponentDialogContextValue {
  return useContext(ComponentDialogContext);
}
