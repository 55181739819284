import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Edge, EdgeProps, getBezierPath, Node } from "reactflow";
import { EdgeMetricV2 } from "../../../graphql/generated";
import {
  hasPipelineTypeFlag,
  PipelineTypeFlags,
} from "../../../types/configuration";
import { EdgeData } from "../../../utils/graph/types";
import { formatMetric } from "../../../utils/graph/utils";
import { classes } from "../../../utils/styles";
import {
  edgeAnimationDuration,
  edgeStrokeOffsetSpan,
  skipFrames,
} from "../../GraphComponents/CustomEdge";
import { PipelineGraphMetricsData } from "../PipelineGraphMetricsData";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { AttributeName } from "../types";
import styles from "./configuration-edge-v2.module.scss";

const ConfigurationEdgeV2: React.FC<EdgeProps<EdgeData>> = ({
  id,
  data,
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  selected,
}) => {
  const [hovered, setHovered] = useState(false);
  const { selectedTelemetryType, editProcessorsOpen, hoveredSet, metricData } =
    useV2PipelineGraph();
  const hiddenRef = useRef(false);
  const pathRef = useRef<SVGPathElement>(null);
  const active = hasPipelineTypeFlag(
    selectedTelemetryType,
    data?.attributes[AttributeName.ActiveTypeFlags] ?? PipelineTypeFlags.ALL,
  );

  const dimmed = hoveredSet.length > 0 && !hoveredSet.includes(id);
  const metricID = data?.attributes.metricID ?? "";

  // Keep track of the hidden ref
  useEffect(() => {
    hiddenRef.current = editProcessorsOpen;
  }, [editProcessorsOpen]);

  // Animation effect
  useEffect(() => {
    let frameCount = 0; // Counter for frame skipping

    // Fun fact:
    //    function animate(time: number) {
    // seems to cause a 10-20% CPU usage increase compared to:
    const animate = (time: number) => {
      frameCount++;

      if (frameCount > skipFrames) {
        if (!pathRef.current) return;

        const currentTime = time % edgeAnimationDuration;
        const progress = currentTime / edgeAnimationDuration;
        const currentOffset = progress * edgeStrokeOffsetSpan;
        pathRef.current.style.strokeDashoffset = `${currentOffset}`;

        frameCount = 0; // Reset the frame counter
      }

      if (active && !hiddenRef.current) {
        requestAnimationFrame(animate);
      }
    };

    if (active && !hiddenRef.current) {
      requestAnimationFrame(animate);
    }
  }, [active, editProcessorsOpen]);

  const [path] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const pathClasses = useMemo(() => {
    return makePathClass({
      active,
      dimmed,
      selected,
      metric: metricData.metric(metricID, selectedTelemetryType),
      maxValue: metricData.maxValue(selectedTelemetryType),
      hovered,
    });
  }, [
    active,
    dimmed,
    selected,
    metricData,
    metricID,
    selectedTelemetryType,
    hovered,
  ]);

  return (
    <path
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={pathRef}
      id={id}
      d={path}
      className={pathClasses}
    />
  );
};

/**
 *
 * @param metric
 * @param edgeID
 * @param period
 * @returns
 */
// TODO(andy): not currently in use, remove if not needed
export function renderStartMetric(
  edgeID: string,
  nodes: Node[],
  edges: Edge[],
  period: string,
  metric?: EdgeMetricV2,
) {
  if (!metric) {
    return null;
  }

  const edge = edges.find((edge) => edge.id === edgeID);
  if (!edge) {
    return null;
  }

  if (!shouldShowStartMetricLabel(edge.source, edge.target, edges, nodes)) {
    return null;
  }

  var startOffset = "2%";
  var textAnchor = "start";

  if (metric.metricID.startsWith("_s0") || metric.metricID.startsWith("_d1")) {
    startOffset = "50%";
    textAnchor = "middle";
  }

  return (
    <g
      key={`${edgeID}-${metric.metricID}`}
      transform={`translate(0 -15)`}
      className="pipeline-metric-label"
    >
      <text>
        <textPath
          className={styles.metric}
          href={`#${edgeID}`}
          startOffset={startOffset}
          textAnchor={textAnchor}
          spacing="auto"
        >
          {formatMetric({ value: metric.value, unit: "B/s" }, period)}
        </textPath>
      </text>
    </g>
  );
}

// TODO(andy): not currently in use, remove if not needed
export function renderEndMetric(
  nodes: Node[],
  edges: Edge[],
  edgeID: string,
  metricData: PipelineGraphMetricsData,
  period: string,
  selectedTelemetryType: string,
) {
  // First determine if we should show this.  To show the edge metric
  // we need to make sure there is not another edge that shares the same
  // target that has a higher source y position.
  const edge = edges.find((edge) => edge.id === edgeID);
  if (!edge) {
    return null;
  }

  if (
    !shouldShowEndMetricLabel(
      edge.source,
      edge.target,
      edges,
      nodes,
      edge.data?.attributes.metricID ?? "",
    )
  ) {
    return null;
  }

  var hasMeasurement = false;
  // The value is the sum of all edges that share the same target
  const otherEdges = edges.filter((e) => e.target === edge.target);
  const value = otherEdges.reduce((acc, e) => {
    const m = metricData.metric(
      e.data?.attributes.metricID,
      selectedTelemetryType,
    );
    if (m) {
      hasMeasurement = true;
      acc += m.value;
    }
    return acc;
  }, 0);

  if (!hasMeasurement) {
    return null;
  }

  const source = nodes.find((node) => node.id === edge.source);
  const target = nodes.find((node) => node.id === edge.target);

  let startOffset = "85%";

  if (source && target) {
    const totalLength = Math.sqrt(
      Math.pow(target.position.x - source.position.x, 2) +
        Math.pow(target.position.y - source.position.y, 2),
    );

    startOffset = `${((totalLength - 72) / totalLength) * 100}%`;
  }

  return (
    <g
      key={`${edgeID}-end-metric`}
      transform={`translate(0 -15)`}
      className="pipeline-metric-label"
    >
      <text>
        <textPath
          className={styles.metric}
          href={`#${edgeID}`}
          startOffset={startOffset}
          textAnchor={"start"}
          spacing="auto"
        >
          {formatMetric({ value, unit: "B/s" }, period)}
        </textPath>
      </text>
    </g>
  );
}

/**
 *  shouldShowStartMetricLabel returns true if there is no other edge that shares
 *  the same source that has a higher target y position.
 * @param edgeID
 * @param edges
 * @param nodes
 */
function shouldShowStartMetricLabel(
  source: string,
  target: string,
  edges: Edge[],
  nodes: Node[],
) {
  const otherEdges = edges.filter((edge) => edge.source === source);

  const targetNode = nodes.find((node) => node.id === target);
  if (targetNode === undefined) {
    return false;
  }

  for (const edge of otherEdges) {
    const edgeTarget = nodes.find((node) => node.id === edge.target);
    if (edgeTarget && edgeTarget.position.y < targetNode.position.y) {
      return false;
    }
  }
  return true;
}

/**
 * shouldShowEndMetricLabel returns true if there are no other edges
 * with the same target that have a higher source y position.
 * @param source
 * @param target
 * @param edges
 * @param nodes
 * @param metricID
 * @returns
 */
function shouldShowEndMetricLabel(
  source: string,
  target: string,
  edges: Edge[],
  nodes: Node[],
  metricID: string,
) {
  // Never show for source-> source processors or destination processors -> destination
  if (metricID.startsWith("_s0") || metricID.startsWith("_d1")) {
    return false;
  }

  const otherEdges = edges.filter((edge) => edge.target === target);

  const sourceNode = nodes.find((node) => node.id === source);
  if (sourceNode === undefined) {
    return false;
  }

  for (const edge of otherEdges) {
    const edgeSource = nodes.find((node) => node.id === edge.source);
    if (edgeSource && edgeSource.position.y < sourceNode.position.y) {
      return false;
    }
  }
  return true;
}

type makePathClassArg = {
  active: boolean;
  dimmed: boolean;
  metric?: EdgeMetricV2;
  maxValue: number;
  selected?: boolean;
  hovered: boolean;
};

function makePathClass({
  active,
  dimmed,
  metric,
  maxValue,
  selected,
  hovered,
}: makePathClassArg) {
  const classNames = [styles.noFill];

  active
    ? classNames.push(styles.activeRoute)
    : classNames.push(styles.inactiveRoute);

  active && classNames.push(getWeightedClassName(maxValue, metric));
  dimmed && classNames.push(styles.dimmed);
  selected && classNames.push(styles.selected);
  hovered && !selected && classNames.push(styles.hovered);
  return classes(classNames);
}

export const getWeightedClassName = (
  maxValue: number,
  metric?: EdgeMetricV2,
) => {
  if (metric == null) {
    return styles.inactive;
  }

  return getWidthClass(metric.value, maxValue);
};

function getWidthClass(rawValue: number, maxValue: number) {
  const ratio = rawValue / maxValue;
  if (ratio >= 1) {
    return styles.w5;
  }
  const scaled = Math.floor(ratio * 5 + 1);
  const widthStyle = `w${scaled}`;
  return styles[widthStyle];
}

export default memo(ConfigurationEdgeV2);
