import { NodeProps } from "reactflow";
import { ConnectorCard } from "../../Cards/ConnectorCard";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName, V2NodeData } from "../types";

export const ConnectorNode: React.FC<NodeProps<V2NodeData>> = ({
  data,
  id,
}) => {
  const { setHoveredSet } = useV2PipelineGraph();
  const { attributes } = data;

  return (
    <RoutingNodeWrapper
      componentType={"connectors"}
      componentPath={attributes[AttributeName.ComponentPath]}
    >
      <div
        onMouseEnter={() => {
          setHoveredSet(data.connectedNodesAndEdges);
        }}
        onMouseLeave={() => setHoveredSet([])}
      >
        <ConnectorCard label={id} />
      </div>
    </RoutingNodeWrapper>
  );
};
